import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTeam } from "../../api";
import { paths } from "../../routes";

const ManageTeam: React.FC = () => {
  const { initialised, selectedTeam } = useTeam();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialised && !selectedTeam) {
      navigate(paths.createTeam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialised, selectedTeam]);

  return (
    <div>
      <h3>Manage Team</h3>
    </div>
  );
};

export default ManageTeam;
