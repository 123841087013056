import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { Loader } from "../components";
import {
  IApiKey,
  IApiKeyWithToken,
  ICreateApiKey,
  IUpdateApiKey,
} from "@toapi/api";
import { useTeam } from "./team";

interface IAppSecurityState {
  apikeys: IApiKey[];
  createApiKey(request: ICreateApiKey): Promise<string | null>;
  updateApiKey(id: string, request: IUpdateApiKey): Promise<boolean | null>;
  getApiKeyToken(apikeyId: string): Promise<IApiKeyWithToken | null>;
  refresh(): Promise<void>;
}

const defaultAppSecurityState: IAppSecurityState = {
  apikeys: [],
  createApiKey: () => Promise.resolve(""),
  updateApiKey: () => Promise.resolve(false),
  getApiKeyToken: () => Promise.resolve(null),
  refresh: () => Promise.resolve(),
};

const AppSecurityContext = createContext<IAppSecurityState>(
  defaultAppSecurityState
);

export const useAppSecurity = () => useContext(AppSecurityContext);

export const AppSecurityProvider: React.FC = () => {
  const { selectedApp, useAppResource } = useTeam();
  const [apikeys, loading, { refresh }, apiKeysApi] = useAppResource<IApiKey>(
    "apikeys",
    selectedApp?.id
  );

  if (loading) return <Loader message="Loading API Keys" />;

  return (
    <AppSecurityContext.Provider
      value={{
        apikeys,
        refresh,
        createApiKey: async (request: ICreateApiKey) => {
          const result = apiKeysApi.post<string>({
            body: request,
          });
          await refresh();
          return result;
        },
        updateApiKey: async (id: string, request: IUpdateApiKey) => {
          const result = await apiKeysApi.put<boolean>({
            path: id,
            body: request,
          });
          await refresh();
          return result;
        },
        getApiKeyToken: (apikeyId: string) =>
          apiKeysApi.get<IApiKeyWithToken>({
            path: apikeyId,
          }),
      }}
    >
      <Outlet />
    </AppSecurityContext.Provider>
  );
};
