import styled, { css } from "styled-components";
import { themeColor } from "../common";

const Container = styled.div`
  height: 10px;
  width: 100%;
  position: relative;
  margin: 10px 0;
`;

const baseCss = css`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  transition: width 2s ease-in-out;
`;

const Background = styled.div`
  ${baseCss};
  background: ${themeColor("background-secondary")};
  width: 100%;
`;

const ProgressBar = styled.div<{ percent: number }>`
  ${baseCss};
  background: ${themeColor("success")};
  width: ${(props) => props.percent}%;
`;

interface Props {
  percent?: number;
}

export const Progress: React.FC<Props> = ({ percent = 0 }) => {
  return (
    <Container>
      <Background />
      <ProgressBar percent={percent} />
    </Container>
  );
};
