import React from "react";
import styled from "styled-components";
import { NoDataSVG } from "../assets";

const Container = styled.div`
  text-align: center;
  cursor: default;

  & > img {
    height: 250px;
    width: 100%;
    margin: auto;
    margin-top: 32px;
    cursor: default;
  }

  & > .title {
    margin-top: 40px;
    font-size: 16pt;
  }

  & > .subtitle {
    margin-top: 8px;
    font-size: 12pt;
  }
`;

interface Props {
  title?: string;
  subtitle?: string;
}

export const NoData: React.FC<Props> = ({
  title = "No data found",
  subtitle,
}) => {
  return (
    <Container>
      <img src={NoDataSVG} alt="empty" />
      <p className="title">{title}</p>
      <p className="subtitle">{subtitle}</p>
    </Container>
  );
};
