import React, { useMemo, useState } from "react";
import { plans } from "./plans";
import { Button, Card, ContentCard } from "../../components";
import styled from "styled-components";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";
import { colors } from "../../common";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethod, loadStripe } from "@stripe/stripe-js";
import env from "../../env";
import { useBilling } from "./Provider";
import PaymentMethodForm from "./PaymentMethodForm";
import { ICreatePaymentMethod } from "@toapi/api";
import { useToast } from "../../api";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";

const Container = styled(ContentCard)`
  max-width: 1024px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
`;

const Plan = styled(Card)`
  ul {
    padding-left: 16px;
  }
  li {
    line-height: 1.8rem;
  }
`;

const SubscribeButton = styled(Button)`
  margin-top: 20px;
  grid-column: 2 / 4;
`;

const SelectPlan: React.FC = () => {
  const { notify } = useToast();
  const navigate = useNavigate();
  const { selectPlan } = useBilling();
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);
  const stripePromise = useMemo(() => loadStripe(env.stripe_public_key), []);

  const onPaymentMethod = async (
    paymentMethod: (PaymentMethod & ICreatePaymentMethod) | undefined
  ) => {
    if (selectedPlan) {
      await selectPlan({
        priceId: selectedPlan.id,
        paymentMethodId: paymentMethod?.id ?? null,
        firstname: paymentMethod?.firstname,
        lastname: paymentMethod?.lastname,
        company: paymentMethod?.company,
        vat: paymentMethod?.vat,
        country: paymentMethod?.country,
        address: paymentMethod?.address,
        city: paymentMethod?.city,
      });
      notify("Plan subscribed successfully!", "success");
      navigate(paths.billing);
    }
  };

  return (
    <Container>
      <h2>Select a plan</h2>
      <Content>
        {plans.map((plan) => (
          <Plan key={plan.id}>
            <h3>{plan.name}</h3>
            <hr />
            <ul>
              <li>{plan.datasets} datasets</li>
              <li>{plan.imports} imports</li>
              <li>{plan.rowsPerImport} rows per import</li>
              <li>{plan.endpoints} endpoints</li>
              <li>{plan.calls} API calls per month</li>
              <li>{plan.keys} API Keys</li>
              <li>
                {plan.email ? (
                  <TbCircleCheck color={colors.success} />
                ) : (
                  <TbCircleX color={colors.danger} />
                )}{" "}
                email support
              </li>
              <li>
                {plan.technical ? (
                  <TbCircleCheck color={colors.success} />
                ) : (
                  <TbCircleX color={colors.danger} />
                )}{" "}
                technical support
              </li>
            </ul>
            <Button
              variant={selectedPlan === plan ? "success" : "read"}
              block
              onClick={() => setSelectedPlan(plan)}
            >
              {selectedPlan === plan ? "Selected" : "Select"}
            </Button>
          </Plan>
        ))}
        {selectedPlan?.showPaymentMethod ? (
          <Elements stripe={stripePromise}>
            <PaymentMethodForm onPaymentMethodSelected={onPaymentMethod} />
          </Elements>
        ) : (
          <SubscribeButton
            variant="success"
            size="lg"
            block
            onClick={() => onPaymentMethod(undefined)}
          >
            Subscribe
          </SubscribeButton>
        )}
      </Content>
    </Container>
  );
};

export default SelectPlan;
