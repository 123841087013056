import React from "react";
import { ModalProps, SelectInput, SimpleModal } from "../components";
import { useTeam } from "../api";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = styled(SelectInput)`
  margin: 0;
`;

const TeamSettings: React.FC<ModalProps> = (props) => {
  const navigate = useNavigate();
  const { selectedApp, setApp, apps, teams, selectedTeam, setTeam } = useTeam();

  const onAppChanged = (app: string) => {
    if (app === "new") {
      navigate(paths.createApp);
    } else {
      setApp(app);
      navigate(paths.dashboard);
    }

    props.onClose?.();
  };

  return (
    <SimpleModal {...props} title="Selected team">
      <Container>
        <Select
          flat
          onChange={(e) => setTeam(e.target.value)}
          value={selectedTeam?.id ?? ""}
          name="team"
          label="Team"
        >
          <option></option>
          {teams.map((team) => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </Select>
        <Select
          flat
          onChange={(e) => onAppChanged(e.target.value)}
          value={selectedApp?.id ?? ""}
          name="new app"
          label="App"
        >
          <option></option>
          {apps.map((app) => (
            <option key={app.id} value={app.id}>
              {app.name}
            </option>
          ))}
          <option disabled>────────────────────</option>
          <option value="new">Create a new app</option>
        </Select>
      </Container>
    </SimpleModal>
  );
};

export default TeamSettings;
