import React, { useState } from "react";
import styled from "styled-components";
import { useAppSecurity, useToast } from "../../api";
import { copyToClipboard, themeColor } from "../../common";
import { Button } from "../../components";
import clsx from "clsx";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.hidden {
    text-decoration: underline;
    color: ${themeColor("link-primary")};
  }
`;

const DEFAULT_TOKEN = "********-****-****-****-************";

interface Props {
  id: string;
}

const Token: React.FC<Props> = ({ id }) => {
  const [token, setToken] = useState(DEFAULT_TOKEN);
  const { getApiKeyToken } = useAppSecurity();
  const { notify } = useToast();

  const isDefault = token === DEFAULT_TOKEN;

  const onClick = async () => {
    if (!isDefault) return;

    const newToken = await getApiKeyToken(id);

    if (newToken) {
      setToken(newToken.token);
    }
  };

  const onCopy = () => {
    copyToClipboard(token);
    notify("API key copies!", "success");
  };

  return (
    <Container onClick={onClick} className={clsx({ hidden: isDefault })}>
      {token}
      {!isDefault && (
        <Button variant="success" onClick={onCopy} size="sm">
          Copy
        </Button>
      )}
    </Container>
  );
};

export default Token;
