import React, { useEffect, useState } from "react";
import { FiXCircle } from "react-icons/fi";
import styled from "styled-components";
import { useEndpoints } from "../../../api";
import {
  Button,
  CheckboxInput,
  ErrorMessage,
  SelectInput,
  TextInput,
} from "../../../components";
import { httpMethod, IQueryParam, queryParamType } from "@toapi/api";
import { useConfigureEndpoint } from "./Provider";

const ParamsContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: 2fr 6fr 2fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;

  & input,
  option,
  select {
    font-size: 0.725rem;
  }
`;

const Head = styled.span`
  font-weight: bold;
  font-size: 0.825rem;
`;

const ParamsTab: React.FC = () => {
  const { setIsDirty, reset } = useConfigureEndpoint();
  const { endpoint, updateEndpoint, reloadEndpoint } = useEndpoints();
  const [params, setParams] = useState<IQueryParam[]>(endpoint?.params ?? []);
  const [query, setQuery] = useState<IQueryParam[]>(endpoint?.query ?? []);
  const [error, setError] = useState("");

  useEffect(() => {
    if (endpoint) {
      setParams(endpoint.params ?? []);
      setQuery(endpoint.query ?? []);
    }
  }, [endpoint]);

  const onChange =
    (path: string) =>
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLSelectElement
      >
    ) => {
      setIsDirty();

      setParams(
        params.map((p) => {
          if (p.name === path) {
            // @ts-ignore
            p[e.target.name] =
              e.target.type === "checkbox"
                ? (e.target as HTMLInputElement).checked
                : e.target.value;
          }

          return p;
        })
      );
    };

  const onQueryChange =
    (path: string) =>
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      setIsDirty();

      setQuery(
        query.map((p) => {
          if (p.name === path) {
            // @ts-ignore
            p[e.target.name] =
              e.target.type === "checkbox"
                ? (e.target as HTMLInputElement).checked
                : e.target.value;
          }

          return p;
        })
      );
    };

  const onAddQuery = () => {
    setIsDirty();

    const names = query.filter((c) => c.name === "name");

    setQuery([
      ...query,
      {
        name: names.length > 0 ? "name" + query.length : "name",
        description: "",
        type: queryParamType.string,
        isRequired: false,
      },
    ]);
  };

  const onDeleteQuery = (name: string) => {
    setIsDirty();

    setQuery(query.filter((c) => c.name !== name));
  };

  const onSave = async () => {
    if (!endpoint) return;

    if (
      params.some((c) => ["page", "perpage"].includes(c.name.toLowerCase())) ||
      query.some((c) => ["page", "perpage"].includes(c.name.toLowerCase()))
    ) {
      setError(
        "'page' and 'perPage' are reserved parameters and cannot be declared against your endpoint."
      );
      return;
    }

    reset();

    await updateEndpoint(endpoint.id, {
      params,
      query,
    });
    await reloadEndpoint();
    setError("");
  };

  return (
    <div>
      <h4>Path params</h4>
      <hr />
      {params.length > 0 ? (
        <ParamsContainer>
          <Head>Path</Head>
          <Head>Description</Head>
          <Head>Type</Head>
          <Head>Regex</Head>
          <Head>Min</Head>
          <Head>Max</Head>
          <Head>Required?</Head>
          <Head></Head>
          {params.map((param) => (
            <>
              <span>{param.name}</span>
              <span>
                <TextInput
                  name="description"
                  placeholder="Helpful description"
                  value={param.description}
                  onChange={onChange(param.name)}
                />
              </span>
              <span>
                <SelectInput
                  name="type"
                  value={param.type}
                  onChange={onChange(param.name)}
                >
                  {Object.values(queryParamType).map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
                </SelectInput>
              </span>
              <span>
                <TextInput
                  name="regex"
                  placeholder="Regex"
                  value={param.regex}
                  onChange={onChange(param.name)}
                  disabled={param.type !== queryParamType.pattern}
                />
              </span>
              <span>
                <TextInput
                  name="min"
                  value={param.min?.toString()}
                  onChange={onChange(param.name)}
                />
              </span>
              <span>
                <TextInput
                  name="max"
                  value={param.max?.toString()}
                  onChange={onChange(param.name)}
                />
              </span>
              <span>
                <CheckboxInput
                  name="isRequired"
                  checked={param.isRequired}
                  onChange={onChange(param.name)}
                />
              </span>
              <span />
            </>
          ))}
        </ParamsContainer>
      ) : (
        <p>No path params are set.</p>
      )}
      <h4>Query params</h4>
      <hr />
      {query.length > 0 ? (
        <ParamsContainer>
          <Head>Path</Head>
          <Head>Description</Head>
          <Head>Type</Head>
          <Head>Regex</Head>
          <Head>Min</Head>
          <Head>Max</Head>
          <Head>Required?</Head>
          <Head></Head>
          {query.map((param) => (
            <>
              <span>
                <TextInput
                  name="name"
                  value={param.name}
                  onChange={onQueryChange(param.name)}
                />
              </span>
              <span>
                <TextInput
                  name="description"
                  placeholder="Helpful description"
                  value={param.description}
                  onChange={onQueryChange(param.name)}
                />
              </span>
              <span>
                <SelectInput
                  name="type"
                  value={param.type}
                  onChange={onQueryChange(param.name)}
                >
                  {Object.values(queryParamType).map((p) => (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  ))}
                </SelectInput>
              </span>
              <span>
                <TextInput
                  name="regex"
                  placeholder="Regex"
                  value={param.regex}
                  onChange={onQueryChange(param.name)}
                  disabled={param.type !== queryParamType.pattern}
                />
              </span>
              <span>
                <TextInput
                  name="min"
                  value={param.min?.toString()}
                  onChange={onQueryChange(param.name)}
                />
              </span>
              <span>
                <TextInput
                  name="max"
                  value={param.max?.toString()}
                  onChange={onQueryChange(param.name)}
                />
              </span>
              <span>
                <CheckboxInput
                  name="isRequired"
                  checked={param.isRequired}
                  onChange={onQueryChange(param.name)}
                />
              </span>
              <span>
                <FiXCircle onClick={() => onDeleteQuery(param.name)} />
              </span>
            </>
          ))}
        </ParamsContainer>
      ) : (
        <p>No query params are set.</p>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button
        variant="read"
        onClick={onAddQuery}
        disabled={endpoint?.method !== httpMethod.get}
      >
        Add param
      </Button>
      <hr />
      <Button variant="success" onClick={onSave}>
        Save
      </Button>
    </div>
  );
};

export default ParamsTab;
