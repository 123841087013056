import React from "react";
import { Button, Table } from "../../components";
import { useBilling } from "./Provider";
import { format } from "date-fns";

const PaymentHistory: React.FC = () => {
  const { invoices } = useBilling();
  return (
    <>
      <h3>Payment History</h3>
      <Table>
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Invoice Date</th>
            <th>Invoice URL</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((c) => (
            <tr key={c._id}>
              <td>
                <a target="_blank" href={c.hostedInvoiceUrl} rel="noreferrer">
                  {c._id}
                </a>
              </td>
              <td>{c.amountPaid}</td>
              <td>{c.status}</td>
              <td>{format(new Date(c.created), "dd/MM/yyyy, hh:mm:ss")}</td>
              <td>
                <Button
                  variant="read"
                  size="sm"
                  onClick={() => (window.location.href = c.hostedInvoiceUrl)}
                >
                  Download
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PaymentHistory;
