import React, { useState } from "react";
import { useConfigureEndpoint } from "./Provider";
import { useEndpoints } from "../../../api";
import { IBodyMapping, IQueryParam, requestBodyType } from "@toapi/api";

const BodyTab: React.FC = () => {
  const { setIsDirty, reset } = useConfigureEndpoint();
  const { endpoint, updateEndpoint } = useEndpoints();
  const [bodyType, setBodyType] = useState<requestBodyType>("application/json");
  const [params, setParams] = useState<IQueryParam[]>([]);
  const [mapping, setMapping] = useState<IBodyMapping[]>();

  return <div>body</div>;
};

export default BodyTab;
