import { IAuthResponse, IUserSimple } from "@toapi/api";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeApiRequest, useLoading } from "../common";
import { Loader } from "../components";

const AUTH_TOKEN_KEY = "TO_API_AUTH_TOKEN";
const AUTH_REFRESH_TOKEN_KEY = "TO_API_AUTH_REFRESH_TOKEN";

const saveTokens = (response: IAuthResponse) => {
  localStorage.setItem(AUTH_TOKEN_KEY, response.token);
  localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, response.refreshToken);
};

export const loadTokens = (): {
  token: string | null;
  refreshToken: string | null;
} => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);

  return { token, refreshToken };
};

const clearTokens = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
};

const refreshAccessToken = async (
  refreshToken: string
): Promise<IAuthResponse | null> => {
  try {
    const result = await makeApiRequest<IAuthResponse>("/auth/token", {
      body: JSON.stringify({ refreshToken }),
      method: "POST",
    });
    return result;
  } catch {
    // we can sign out if we can't refresh token
    clearTokens();
    return null;
  }
};

interface AuthContextState {
  initialised: boolean;
  user: IUserSimple | null;
  signUpWithPassword(
    email: string,
    password: string
  ): Promise<IAuthResponse | null>;
  signInWithPassword(email: string, password: string): Promise<void>;
  setProfilePhoto(path: string): Promise<void>;
  logout(): void;
  isAnonymous: boolean;
  isAdmin: boolean;
  token: string | null;
}

const defaultAuthContextState: AuthContextState = {
  initialised: false,
  user: null,
  signUpWithPassword: () => Promise.resolve(null),
  signInWithPassword: () => Promise.resolve(),
  setProfilePhoto: () => Promise.resolve(),
  logout: () => {},
  isAnonymous: true,
  isAdmin: false,
  token: null,
};

const AuthContext = createContext<AuthContextState>(defaultAuthContextState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { loading, setLoading, setLoaded } = useLoading(true);
  const [user, setUser] = useState<IUserSimple | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const onAuth = async () => {
      const { refreshToken } = loadTokens();

      if (refreshToken) {
        const response = await refreshAccessToken(refreshToken);
        if (response) {
          setUser(response.user);
          setToken(response.token);
          saveTokens(response);
        }
      }

      setLoaded();
    };

    onAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  const signUpWithPassword = async (email: string, password: string) => {
    try {
      const response = await makeApiRequest<IAuthResponse>("/auth/sign-up", {
        body: JSON.stringify({ email, password }),
        method: "POST",
      });

      if (response) {
        setUser(response.user);
        setToken(response.token);
        saveTokens(response);
      }
      return response;
    } catch (e) {
      //
      console.log(e);
      return null;
    }
  };

  const signInWithPassword = async (email: string, password: string) => {
    try {
      const response = await makeApiRequest<IAuthResponse>("/auth/sign-in", {
        body: JSON.stringify({ email, password }),
        method: "POST",
      });
      if (response) {
        setUser(response.user);
        setToken(response.token);
        saveTokens(response);
      }
    } catch (e) {
      throw e;
    }
  };

  const logout = () => {
    clearTokens();
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        ...defaultAuthContextState,
        initialised: !loading,
        user,
        isAnonymous: !user,
        isAdmin,
        signInWithPassword,
        signUpWithPassword,
        logout,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
