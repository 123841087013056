import styled from "styled-components";
import { themeColor } from "../../common";
import { Card } from "../../components";

export const Container = styled.div`
  min-height: 100vh;
  padding-top: 150px;
`;

export const Content = styled(Card)`
  width: 400px;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;

  & > hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & > div {
    margin-top: 15px;
    font-size: 0.825rem;

    & > a {
      margin-left: 5px;
      color: ${themeColor("link-primary")};
      font-weight: 500;
    }
  }
`;

export const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  column-gap: 20px;
  justify-items: center;

  & > a {
    text-decoration: none;
    color: ${themeColor("text-primary")};
    font-weight: bold;
    opacity: 0.3;

    &.active {
      opacity: 1;
    }
  }
`;
