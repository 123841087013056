export const paths = {
  home: "/",
  signup: "/signup",
  login: "/login",
  createTeam: "/create-team",
  manageTeam: "/manage-team",
  createApp: "/apps/create",
  configureApp: "/configure",
  billing: "/billing",
  paymentMethod: "payment-method",
  selectPlan: "plan",
  endpoints: "/endpoints",
  createEndpoint: "/endpoints/create",
  viewEndpoint: "/endpoints/:endpointId",
  testEndpoint: "/endpoints/:endpointId/test",
  configureEndpoint: "/endpoints/:endpointId/configure",
  datasets: "/datasets",
  createDataset: "/datasets/create",
  viewDataset: "/datasets/:id",
  uploadDataset: "/datasets/:id/upload",
  configureDatasetColumns: "/datasets/:id/columns",
  uploadHistory: "/datasets/:id/history",
  apikeys: "/apikeys",
  createApikey: "/apikeys/create",
  updateApikey: "/apikeys/:id/update",
  monitor: "/monitor",
  swagger: "/:id/swagger",
  dashboard: "/dashboard",
  marketplace: "/marketplace",
};
