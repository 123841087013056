import { addMinutes, format } from "date-fns";
import React, { useMemo } from "react";
import { useMonitor } from "../../api";
import Chart60Mins from "./Chart60Mins";
import { next5MinDate } from "./date-utils";

const FailuresChart60Mins: React.FC = () => {
  const { stats } = useMonitor();

  const data = useMemo(() => {
    const result = [];

    const today = next5MinDate();
    let som = addMinutes(today, -60);

    do {
      result.push({
        date: format(som, "p"),
        requests: stats?.t60m.failures[som.toString()] ?? 0,
      });

      som = addMinutes(som, 5);
    } while (som < today);

    return result;
  }, [stats]);

  return (
    <Chart60Mins
      data={data}
      getLabel={() => "Failed requests"}
      dataKey="requests"
      stroke="#FF461F"
    />
  );
};

export default FailuresChart60Mins;
