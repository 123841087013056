import React from "react";
import { Button, ContentCard, TextInput } from "../../components";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { paths } from "../../routes";
import { useAppSecurity } from "../../api";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
  description: yup.string(),
});

const CreateApiKey: React.FC = () => {
  const navigate = useNavigate();
  const { createApiKey } = useAppSecurity();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (
      { name, description },
      { setSubmitting, setFieldError }
    ) => {
      try {
        await createApiKey({ name, description });
        navigate(paths.apikeys);
      } catch (e: any) {
        // TODO handle error
        setFieldError("name", e.message);
      }
    },
  });
  return (
    <ContentCard>
      <h3>Create api key</h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="Api Key name"
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <TextInput
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          touched={formik.touched.description}
          error={formik.errors.description}
        />

        <hr />
        <Button variant="success">Create API Key</Button>
      </form>
    </ContentCard>
  );
};

export default CreateApiKey;
