import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  getMethodRetrievalType,
  httpMethod,
  IApiKeyWithToken,
  IDatasetSimple,
  IEndpointExtended,
  IEndpointSimple,
} from "@toapi/api";
import { useAuth } from "./auth";
import { useTeam } from "./team";

interface EndpointsContextState {
  initialised: boolean;
  loadingEndpoints: boolean;
  endpoints: IEndpointSimple[];
  endpoint: IEndpointExtended | null;
  datasets: IDatasetSimple[];
  createEndpoint(
    name: string,
    path: string,
    method: httpMethod,
    retrievalType: getMethodRetrievalType,
    datasetId: string
  ): Promise<string | null>;
  updateEndpoint(
    endpointId: string,
    endpoint: Partial<Omit<IEndpointSimple, "id">>
  ): Promise<void>;
  defaultApiKey: IApiKeyWithToken | null;
  reloadEndpoint(): Promise<void>
}

const defaultEndpointsContextState: EndpointsContextState = {
  initialised: false,
  loadingEndpoints: true,
  endpoints: [],
  datasets: [],
  endpoint: null,
  createEndpoint: () => Promise.resolve(""),
  updateEndpoint: () => Promise.resolve(),
  defaultApiKey: null,
  reloadEndpoint:() => Promise.resolve()
};

const EndpointsContext = createContext<EndpointsContextState>(
  defaultEndpointsContextState
);

export const useEndpoints = () => useContext(EndpointsContext);

export const EndpointsProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { endpointId } = useParams();
  const [endpoint, setEndpoint] = useState<IEndpointExtended | null>(null);
  const { user } = useAuth();
  const {
    selectedApp,
    initialised: teamsInitialised,
    useAppResource,
  } = useTeam();
  const [
    endpoints,
    loadingEndpoints,
    { refresh: reloadEndpoints },
    endpointsApi,
  ] = useAppResource<IEndpointSimple>("endpoints", selectedApp?.id);
  const [datasets] = useAppResource<IDatasetSimple>(
    "datasets",
    selectedApp?.id
  );
  const [defaultApiKey, setDefaultApiKey] = useState<IApiKeyWithToken | null>(
    null
  );

  const [, , , apiKeysApi] = useAppResource<IApiKeyWithToken>(
    "apikeys",
    selectedApp?.id,
    false
  );

  const [initialising, setInitialising] = useState(true);

  useEffect(() => {
    if (!teamsInitialised) return;

    setInitialising(false);
  }, [teamsInitialised]);

  const loadDefaultApiKey = async () => {
    if (selectedApp?.id) {
      const defaultApiKey = await apiKeysApi.get<IApiKeyWithToken>({
        path: "default",
      });

      setDefaultApiKey(defaultApiKey);
    }
  };

  const loadEndpoint = async () => {
    if (endpointId || endpoint?.id !== endpointId) {
      const currentEndpoint = await endpointsApi.get<IEndpointExtended>({
        path: endpointId,
      });
      setEndpoint(currentEndpoint);
    }
  };

  const reloadEndpoint= async () => {
    await loadEndpoint()
  }

  useEffect(() => {
    loadEndpoint();
    loadDefaultApiKey();
  }, [endpoint?.id, endpointId, endpoints, selectedApp?.id]);

  if (initialising) return null; // TODO replace with loader

  const onCreateEndpoint = async (
    name: string,
    path: string,
    method: httpMethod,
    retrievalType: getMethodRetrievalType,
    datasetId: string
  ) => {
    const id = await endpointsApi.post<string>({
      body: {
        name,
        path,
        method,
        retrievalType,
        datasetId,
      },
    });

    return id;
  };

  const updateEndpoint = async (
    endpointId: string,
    endpoint: Partial<Omit<IEndpointSimple, "id">>
  ) => {
    await endpointsApi.put({
      path: endpointId,
      body: endpoint,
    });
  };

  return (
    <EndpointsContext.Provider
      value={{
        initialised: initialising,
        loadingEndpoints,
        endpoints,
        datasets,
        endpoint,
        createEndpoint: onCreateEndpoint,
        updateEndpoint,
        defaultApiKey,
        reloadEndpoint
      }}
    >
      <Outlet />
    </EndpointsContext.Provider>
  );
};
