import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { mobileMediaQuery, themeColor } from "../../common";
import { Button } from "../../components";
import { paths } from "../../routes";
import { PaddedContainer } from "./PaddedContainer";

const Container = styled.div`
  background-color: ${themeColor("background-tertiary")};
  padding: 50px 0;
  text-align: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    ${mobileMediaQuery} {
      width: 100%;

      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  button {
    width: 200px;
  }
`;

const Introduction: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <PaddedContainer>
        <h2 className="title">Turn your data into NoCode APIs</h2>
        <p className="description">
          Save time & money by turning your data into APIs. Upload your CSV,
          Excel, or JSON files and turn them into fully functioning APIs without
          any backend code.
        </p>
        <Button
          block
          flat
          variant="read"
          onClick={() => navigate(paths.signup)}
        >
          Start for free
        </Button>
      </PaddedContainer>
    </Container>
  );
};

export default Introduction;
