import React from "react";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { mobileMediaQuery, themeColor } from "../../common";
import { Button } from "../../components";
import { PaddedContainer } from "./PaddedContainer";

const Container = styled.div`
  background-color: ${themeColor("background-tertiary")};
  padding: 50px 0;

  ${mobileMediaQuery} {
    padding: 10px 0;
  }
`;

const Title = styled.h4`
  font-size: 1.6rem;
  text-align: center;
  margin: 0;
`;

const Subtitle = styled.h6`
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400;
  color: ${themeColor("text-primary")};
  margin-top: 15px;

  ${mobileMediaQuery} {
    padding: 0 10px;
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  ${mobileMediaQuery} {
    td:first-of-type {
      position: sticky;
      left: 0;
      border-left: none;
      background: ${themeColor("background-tertiary")};
    }
  }
`;

const Table = styled.table`
  width: 100%;

  th {
    font-size: 1.2rem;
    font-weight: 400;
  }
  td,
  th {
    padding: 10px 0;
    border-bottom: 0.5px solid ${themeColor("border-primary")};
  }

  td {
    text-align: center;

    &:first-of-type {
      text-align: left;
    }
  }

  .price {
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const HighLimits = styled.p`
  margin: 50px 0;
  padding: 20px 0;
  text-align: center;
  font-size: 1.1rem;
  color: ${themeColor("text-primary")};
  a {
    color: ${themeColor("link-primary")};
  }

  ${mobileMediaQuery} {
    padding: 0 10px;
    margin: 20px 0;
  }
`;

const Pricing: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <Container id="pricing">
      <PaddedContainer>
        <Title>Our Pricing</Title>
        <Subtitle>
          Pricing to cater for all. Hobbiest, Startups and Business can all find
          benefit of using <b>apiGO</b>
        </Subtitle>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Free</th>
                <th>Basic</th>
                <th>Professional</th>
                <th>Plus</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <span className="price">$0</span> <small>month</small>
                </td>
                <td>
                  <span className="price">$9</span> <small>month</small>
                </td>
                <td>
                  <span className="price">$49</span> <small>month</small>
                </td>
                <td>
                  <span className="price">$199</span> <small>month</small>
                </td>
              </tr>
              <tr>
                <td>Datasets</td>
                <td>3</td>
                <td>20</td>
                <td>unlimited</td>
                <td>unlimited</td>
              </tr>
              <tr>
                <td>Imports</td>
                <td>100</td>
                <td>500</td>
                <td>5k</td>
                <td>10k</td>
              </tr>
              <tr>
                <td>Rows per import</td>
                <td>100</td>
                <td>500</td>
                <td>50k</td>
                <td>500k</td>
              </tr>
              <tr>
                <td>Endpoints</td>
                <td>unlimited</td>
                <td>unlimited</td>
                <td>unlimited</td>
                <td>unlimited</td>
              </tr>
              <tr>
                <td>API Calls</td>
                <td>300 requests/month</td>
                <td>10k requests/month</td>
                <td>150k requests/month</td>
                <td>1 million requests/month</td>
              </tr>
              <tr>
                <td>API Keys</td>
                <td>1</td>
                <td>5</td>
                <td>unlimited</td>
                <td>unlimited</td>
              </tr>
              <tr>
                <td>Email Support</td>
                <td>
                  <TbCircleX color={colors.danger} />
                </td>
                <td>
                  <TbCircleCheck color={colors.success} />
                </td>
                <td>
                  <TbCircleCheck color={colors.success} />
                </td>
                <td>
                  <TbCircleCheck color={colors.success} />
                </td>
              </tr>
              <tr>
                <td>Technical support</td>
                <td>
                  <TbCircleX color={colors.danger} />
                </td>
                <td>
                  <TbCircleX color={colors.danger} />
                </td>
                <td>
                  <TbCircleCheck color={colors.success} />
                </td>
                <td>
                  <TbCircleCheck color={colors.success} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Button
                    variant="read"
                    block
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Button>
                </td>
                <td>
                  <Button
                    variant="read"
                    block
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Button>
                </td>
                <td>
                  <Button
                    variant="read"
                    block
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Button>
                </td>
                <td>
                  <Button
                    variant="read"
                    block
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
        <HighLimits>
          We also have custom plans with higher limits and dedicated servers.{" "}
          <Link to="">Contact us</Link> with your requirements.
        </HighLimits>
      </PaddedContainer>
    </Container>
  );
};

export default Pricing;
