import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { themeColor } from "../../common";
import { Button } from "../../components";
import { paths } from "../../routes";
import { PaddedContainer } from "./PaddedContainer";

const Container = styled.div`
  background-color: ${themeColor("background-primary")};
  padding: 50px 0;
  text-align: center;
  color: ${themeColor("white")};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 200px;
      margin: 20px 0;
    }
  }
`;

const FinalCallToAction: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <PaddedContainer>
        <h4>Easy to use. Serverless. Fewer headaches for you and your time.</h4>
        <Button
          variant="read"
          block
          flat
          onClick={() => navigate(paths.signup)}
        >
          Sign Up Now!
        </Button>
      </PaddedContainer>
    </Container>
  );
};

export default FinalCallToAction;
