import { createContext, PropsWithChildren, useContext, useState } from "react";

interface IConfigureEndpointState {
  isDirty: boolean;
  setIsDirty(): void;
  reset(): void;
}

const defaultState: IConfigureEndpointState = {
  isDirty: false,
  setIsDirty: () => {
    //
  },
  reset: () => {
    //
  },
};

const ConfigureEndpointContext = createContext(defaultState);

export const useConfigureEndpoint = () => useContext(ConfigureEndpointContext);

export const ConfigureEndpointProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  return (
    <ConfigureEndpointContext.Provider
      value={{
        isDirty,
        setIsDirty: () => setIsDirty(true),
        reset: () => setIsDirty(false),
      }}
    >
      {children}
    </ConfigureEndpointContext.Provider>
  );
};
