import styled from "styled-components";
import { themeColor } from "../common";
import { Card } from "./Card";

export const ContentCard = styled(Card)`
  width: 100%;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: scroll;

  & h3 {
    margin: 10px 0;
  }

  & h4 {
    font-size: 0.825rem;
    color: ${themeColor("text-tertiary")};
    margin: 5px 0;
  }
`;
