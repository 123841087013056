import React from "react";
import { FiInfo, FiPlusCircle, FiSettings, FiTrash2 } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Badge,
  Button,
  Card,
  NoData,
  SimpleModal,
  Table,
  useModalState,
} from "../../components";
import { datasetIcons } from "./common";
import { paths } from "../../routes";
import { useDatasets } from "../../api";
import { datasetStatus } from "@toapi/api";
import { colors } from "../../common";

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & button {
    margin-left: 10px;
  }
`;

const TFoot = styled.tfoot`
  background-color: ${colors["white"]};

  & :hover {
    background-color: ${colors["white"]};
  }
`;

const Datasets: React.FC = () => {
  const { datasets, deleteDataset } = useDatasets();
  const deleteDatasetModal = useModalState();

  const navigate = useNavigate();

  const getVariant = (status: datasetStatus) => {
    switch (status) {
      case datasetStatus.created:
        return "info";
      case datasetStatus.active:
        return "success";
      case datasetStatus.error:
        return "danger";
      default:
        return "warning";
    }
  };

  const onToggleDeleteDataset =
    (id: string) => (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      deleteDatasetModal.onOpen(id);
    };

  const onDeleteDataset = async (id: string) => {
    await deleteDataset(id);
  };

  return (
    <div>
      <Toolbar>
        <h3>Datasets</h3>
        <div className="spacer" />
        {/* <TextInput name="search" placeholder="Search" inline /> */}
        <Button variant="success" onClick={() => navigate(paths.createDataset)}>
          <FiPlusCircle /> Add Dataset
        </Button>
      </Toolbar>
      <Card>
        <div>
          <Table>
            <thead>
              <tr>
                <th>Dataset</th>
                <th>Type</th>
                <th>Records</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {datasets.map(
                ({ id, name, importType, numberOfRecords, status }) => {
                  const Icon = datasetIcons[importType];
                  return (
                    <tr key={id} onClick={() => navigate(`/datasets/${id}`)}>
                      <td>
                        <Link to={`/datasets/${id}`}>{name}</Link>
                      </td>
                      <td>
                        <Icon /> DATASET - {importType.toLocaleUpperCase()}
                        <FiInfo />
                      </td>
                      <td>{numberOfRecords} records</td>
                      <td>
                        <Badge variant={getVariant(status)}>
                          {status.toLocaleUpperCase()}
                        </Badge>
                      </td>
                      <td>
                        <FiSettings />
                      </td>
                      <td>
                        <FiTrash2 onClick={onToggleDeleteDataset(id)} />
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
            {datasets.length === 0 && (
              <TFoot>
                <tr>
                  <td colSpan={6}>
                    <NoData
                      title="No datasets found."
                      subtitle="Create a dataset now and start using your endpoints."
                    />
                  </td>
                </tr>
              </TFoot>
            )}
          </Table>
        </div>
      </Card>
      <SimpleModal
        open={deleteDatasetModal.open}
        onClose={deleteDatasetModal.onClose}
        title="Are you sure?"
        onDanger={() =>
          deleteDatasetModal.ref
            ? onDeleteDataset(deleteDatasetModal.ref)
            : undefined
        }
        danger="Delete"
      >
        You are about to delete a dataset. This action is not reversible.
      </SimpleModal>
    </div>
  );
};

export default Datasets;
