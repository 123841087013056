import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ContentCard, ErrorMessage } from "../../components";
import PaymentMethodCardCollector from "./PaymentMethodCardCollector";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethod, loadStripe } from "@stripe/stripe-js";
import env from "../../env";
import { useBilling } from "./Provider";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";
import { useToast } from "../../api";

// TODO should I be setting this width to something standard
const Container = styled(ContentCard)`
  max-width: 540px;
  text-align: center;
`;

const UpdatePaymentMethod: React.FC = () => {
  const stripePromise = useMemo(() => loadStripe(env.stripe_public_key), []);
  const navigate = useNavigate();
  const { notify } = useToast();
  const [error, setError] = useState("");
  const { updatePaymentMethod } = useBilling();

  const onPaymentMethod = async (data: PaymentMethod) => {
    const result = await updatePaymentMethod(data.id);

    if (result) {
      notify("Payment method updated successfully.", "success");
      navigate(paths.billing);
    } else {
      setError("An error occured.");
    }
  };

  return (
    <Container>
      <h3>Update Payment Method</h3>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Elements stripe={stripePromise}>
        <PaymentMethodCardCollector
          onCardCollected={onPaymentMethod}
          button="Save"
          buttonSubmitting="Saving..."
        />
      </Elements>
    </Container>
  );
};

export default UpdatePaymentMethod;
