import styled from "styled-components";

interface BadgeProps {
  variant: "success" | "info" | "warning" | "danger" | "read";
  size?: "sm" | "md" | "lg";
  ml?: string;
  mr?: string;
}

export const Badge = styled.span<BadgeProps>`
  color: white;
  background-color: ${(props) => props.theme.colors[props.variant]};
  padding: 4px;
  font-size: 0.625rem;
  font-weight: 700;
  border-radius: 4px;
  ${(props) => (props.size === "sm" ? "font-size: 0.625rem" : "")};
  ${(props) => (props.size === "md" ? "font-size: 0.925rem" : "")};
  ${(props) => (props.size === "lg" ? "font-size: 1.125rem" : "")};

  ${(props) => (props.ml ? `margin-left: ${props.ml}` : "")};
  ${(props) => (props.mr ? `margin-right: ${props.mr}` : "")};
`;
