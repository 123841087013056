import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button, useModalState } from "../components";
import { FaBars, FaTimesCircle } from "react-icons/fa";
import { themeColor } from "../common";
import { FaTimes } from "react-icons/fa";

const HamburgerButton = styled(Button)`
  padding: 0;
  grid-column: 1 / 2;
  justify-self: flex-end;
`;

const NoScrollOnBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const CloseButton = styled(Button)`
  grid-column: 1 / 1;
  padding: 0;
  justify-self: flex-end;
  width: 24px;
  height: 24px;
`;

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${themeColor("background-tertiary")};
  z-index: 100;
  display: grid;
  grid-template-columns: 44px 1fr 44px;
  grid-template-rows: 65px 1fr;
  align-items: center;
`;

const MainHamburgerMenu: React.FC = () => {
  const { onOpen, open, onClose } = useModalState();

  return (
    <>
      <HamburgerButton
        variant="transparent"
        size="lg"
        onClick={onOpen}
        aria-label={"open menu"}
      >
        <FaBars size="32" color="black" />
      </HamburgerButton>
      {open && (
        <MenuContainer>
          <NoScrollOnBody />
          <CloseButton variant="transparent" size="lg" onClick={onClose}>
            <FaTimes size="32" color="black" />
          </CloseButton>
        </MenuContainer>
      )}
    </>
  );
};

export default MainHamburgerMenu;
