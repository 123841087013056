import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IAppSimple, IAppSummary, ITeamSimple } from "@toapi/api";
import { useResource, UseResourceResponseType } from "../common";
import { useAuth } from "./auth";

const SELECTED_TEAM_ID = "toapi-teamid";
const SELECTED_APP_ID = "toapi-appid";

interface TeamsContextState {
  initialised: boolean;
  selectedTeam: ITeamSimple | null;
  teams: ITeamSimple[];
  setTeam(teamId: string | ITeamSimple): Promise<void>;
  createTeam(name: string): Promise<ITeamSimple | null>;
  selectedApp: IAppSimple | null;
  apps: IAppSimple[];
  setApp(appId: string): Promise<void>;
  createApp(name: string): Promise<IAppSimple | null>;
  updateApp(
    name: string,
    description: string,
    developer: string,
    website: string,
    docs: string
  ): Promise<boolean | null>;
  useAppResource<A>(
    resource: string,
    id?: string,
    initialLoad?: boolean
  ): UseResourceResponseType<A>;
  useTeamResource<A>(
    resource: string,
    id?: string,
    initialLoad?: boolean
  ): UseResourceResponseType<A>;
  summary: IAppSummary | null;
  reloadTeam(): Promise<void>;
}

const defaultTeamsContextState: TeamsContextState = {
  initialised: false,
  selectedTeam: null,
  teams: [],
  setTeam: () => Promise.resolve(),
  createTeam: () => Promise.resolve(null),
  selectedApp: null,
  apps: [],
  createApp: () => Promise.resolve(null),
  updateApp: () => Promise.resolve(true),
  setApp: () => Promise.resolve(),
  // @ts-ignore
  useAppResource: () => {},
  // @ts-ignore
  useTeamResource: () => {},
  summary: null,
  reloadTeam: () => Promise.resolve(),
};

const TeamsContext = createContext<TeamsContextState>(defaultTeamsContextState);

export const useTeam = () => useContext(TeamsContext);

export const TeamsProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [initialising, setInitialising] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState<ITeamSimple | null>(null);
  const [teams, isTeamsLoading, { refresh: reloadTeam }, teamsApi] =
    useResource<ITeamSimple>("teams");
  const [apps, isAppsLoading, { refresh: reloadApps }, appsApi] =
    teamsApi.useResource<IAppSimple>("apps", selectedTeam?.id);
  const [selectedApp, setSelectedApp] = useState<IAppSimple | null>(null);
  const [summary, setSummary] = useState<IAppSummary | null>(null);
  const { isAnonymous } = useAuth();

  useEffect(() => {
    if (isAnonymous) {
      setSelectedTeam(null);
      setSelectedApp(null);
      localStorage.removeItem(SELECTED_TEAM_ID);
      localStorage.removeItem(SELECTED_APP_ID);
    }
  }, [isAnonymous]);

  useEffect(() => {
    if (isTeamsLoading) return;
    if (teams.length > 0) {
      const teamId = localStorage.getItem(SELECTED_TEAM_ID);
      const team = teams.find((t) => t.id === teamId);
      if (team) {
        setSelectedTeam(team);
      } else {
        setSelectedTeam(teams[0]);
      }
    }
    setInitialising(false);
  }, [isTeamsLoading, teams, initialising]);

  useEffect(() => {
    if (selectedApp) {
      appsApi
        .get<IAppSummary>({
          path: `${selectedApp.id}/summary`,
        })
        .then(setSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  useEffect(() => {
    if (isAppsLoading) return;

    if (apps.length > 0) {
      const appId = localStorage.getItem(SELECTED_APP_ID);
      const app = apps.find((a) => a.id === appId);

      if (app) {
        setSelectedApp(app);
      } else {
        setSelectedApp(apps[0]);
      }
    } else {
      setSelectedApp(null);
    }
  }, [apps, isAppsLoading]);

  const onSetTeam = useCallback(
    async (t: string | ITeamSimple) => {
      let team: ITeamSimple | null = null;

      if (typeof t === "string") {
        const id = t as unknown as string;

        team = teams.find((t) => t.id === id) ?? null;
      } else {
        team = t as ITeamSimple;
      }

      if (team) {
        setSelectedTeam(team);
        localStorage.setItem(SELECTED_TEAM_ID, team.id);
      }
    },
    [teams]
  );

  const onCreateTeam = async (name: string) => {
    const result = await teamsApi.post<ITeamSimple | null>({ body: { name } });
    if (result) {
      onSetTeam(result);
    }
    return result;
  };

  const onCreateApp = async (name: string) => {
    const app = await appsApi.post<IAppSimple | null>({ body: { name } });
    if (app) {
      localStorage.setItem(SELECTED_APP_ID, app.id);
      await reloadApps();
    }

    return app;
  };

  const onUpdateApp = async (
    name: string,
    description: string,
    developer: string,
    website: string,
    docs: string
  ) => {
    if (!selectedApp) return false;

    const result = await appsApi.put<boolean>({
      path: selectedApp.id,
      body: { name, description, developer, website, docs },
    });

    await reloadApps();
    return result;
  };

  const onSetApp = useCallback(
    async (id: string) => {
      const app = apps.find((a) => a.id === id);

      if (app) {
        setSelectedApp(app);
        localStorage.setItem(SELECTED_APP_ID, app.id);
      }
    },
    [apps]
  );

  return (
    <TeamsContext.Provider
      value={{
        initialised: !initialising && !isTeamsLoading,
        selectedTeam,
        teams,
        setTeam: onSetTeam,
        createTeam: onCreateTeam,
        selectedApp,
        apps,
        createApp: onCreateApp,
        updateApp: onUpdateApp,
        setApp: onSetApp,
        useTeamResource: teamsApi.useResource,
        useAppResource: appsApi.useResource,
        summary,
        reloadTeam: reloadTeam,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
