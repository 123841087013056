import { httpMethod } from "@toapi/api";
import React, { useState } from "react";
import styled from "styled-components";
import { useEndpoints } from "../../api";
import { ContentCard, Tab, Tabs } from "../../components";
import {
  ParamsTab,
  FilterTab,
  ConfigureEndpointProvider,
  useConfigureEndpoint,
  BodyTab,
} from "./Configure";

const Content = styled(ContentCard)`
  max-width: 1024px;
`;

const ConfigureEndpoint: React.FC = () => {
  const { isDirty } = useConfigureEndpoint();
  const { endpoint } = useEndpoints();
  const [activeTab, setActiveTab] = useState("0");

  return (
    <Content>
      <h3>Configure endpoint</h3>
      <hr />
      <Tabs activeTabKey={activeTab} setTab={setActiveTab}>
        <Tab
          tabKey="0"
          title={`Params ${activeTab === "0" && isDirty ? "*" : ""}`}
          disabled={isDirty}
        >
          <ParamsTab />
        </Tab>
        <Tab
          tabKey="2"
          title={`Body ${activeTab === "2" && isDirty ? "*" : ""}`}
          disabled={endpoint?.method === httpMethod.get || isDirty}
        >
          <BodyTab />
        </Tab>
        <Tab
          tabKey="3"
          title={`Filters ${activeTab === "3" && isDirty ? "*" : ""}`}
          disabled={endpoint?.method !== httpMethod.get || isDirty}
        >
          <FilterTab />
        </Tab>
        <Tab tabKey="4" title="Projection (coming soon)" disabled>
          This tab is also cool
        </Tab>
        <Tab tabKey="5" title="Secure">
          This tab is also cool
        </Tab>
      </Tabs>
    </Content>
  );
};

export default ConfigureEndpoint;
