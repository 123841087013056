import React, { useState } from "react";
import {
  Button,
  Card,
  ErrorMessage,
  Label,
  SelectInput,
  TextInput,
} from "../../components";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import countries from "./countries.json";
import styled from "styled-components";
import { PaymentMethod } from "@stripe/stripe-js";
import { colors } from "../../common";
import { ICreatePaymentMethod } from "@toapi/api";
import PaymentMethodCardCollector from "./PaymentMethodCardCollector";

const Billing = styled(Card)`
  grid-column: 1 / 5;
  padding: 20px 120px;
  form {
    width: 75%;
    margin: auto;

    h2 {
      text-align: center;
    }
  }
`;

interface Props {
  onPaymentMethodSelected(
    data: PaymentMethod & ICreatePaymentMethod
  ): Promise<void>;
}

const PaymentMethodForm: React.FC<Props> = ({ onPaymentMethodSelected }) => {
  const [error, setError] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [vat, setVat] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const onPaymentMethod = async (
    paymentMethod: PaymentMethod & Partial<ICreatePaymentMethod>
  ) => {
    if (!firstname || !lastname || !country || !address || !city) {
      setError("Enter required (*) fields.");
      return;
    }

    onPaymentMethodSelected({
      ...paymentMethod,
      firstname,
      lastname,
      company,
      vat,
      country,
      address,
      city,
      cardBrand: paymentMethod.card?.brand ?? "",
      cardExpMonth: paymentMethod.card?.exp_month.toString() ?? "",
      cardLast4: paymentMethod.card?.last4 ?? "",
    });
  };

  return (
    <Billing>
      <h2>Setup payment</h2>
      <TextInput
        name="firstname"
        label="First name"
        required
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
      />
      <TextInput
        name="lastname"
        label="Last name"
        required
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
      />
      <TextInput
        name="company"
        label="Company"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <TextInput
        name="vat"
        label="VAT Number"
        value={vat}
        onChange={(e) => setVat(e.target.value)}
      />
      <SelectInput
        name="country"
        label="Country *"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
      >
        <option value="" />
        {countries.map((c) => (
          <option value={c.code}>{c.name}</option>
        ))}
      </SelectInput>
      <TextInput
        name="address"
        label="Address"
        required
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <TextInput
        name="city"
        label="City"
        required
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <Label>Card information</Label>
      <div>{error && <ErrorMessage>{error}</ErrorMessage>}</div>
      <PaymentMethodCardCollector onCardCollected={onPaymentMethod} />
    </Billing>
  );
};

export default PaymentMethodForm;
