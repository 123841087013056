import React, { useState } from "react";
import {
  Button,
  CheckboxInput,
  ContentCard,
  ErrorMessage,
  FileUploadInput,
} from "../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDatasets } from "../../api";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  updateRows: yup.boolean(),
  deleteRows: yup.boolean(),
  deleteColumns: yup.boolean(),
  errorOnValidation: yup.boolean(),
});

const MAX_FILE_SIZE = 1048576 * 5;

const UploadDataset: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const { uploadDataset, dataset } = useDatasets();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      updateRows: true,
      deleteRows: true,
      deleteColumns: true,
      errorOnValidation: true,
    },
    validationSchema,
    onSubmit: async (value, { setSubmitting, setFieldError }) => {
      if (!file) return;
      if (file.size >= MAX_FILE_SIZE) {
        setFieldError("updateRows", "File exceeds limit of 5MB per upload");
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      try {
        if (!dataset) return;

        await uploadDataset(file);
        if (dataset.metadata?.status === "configured") {
          navigate(`/datasets/${dataset.id}`);
        } else {
          navigate(`/datasets/${dataset.id}/columns`);
        }
      } catch (e: any) {
        // TODO handle errors
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <ContentCard>
      <h3>Upload dataset</h3>
      <h4>{dataset?.name}</h4>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <FileUploadInput label="Upload file" onChange={setFile} />
        <ErrorMessage>{formik.errors.updateRows}</ErrorMessage>
        <hr />
        {/* <CheckboxInput
          name="updateRows"
          label="Update rows"
          checked={formik.values.updateRows}
          onChange={(e) => formik.setFieldValue("updateRows", e.target.checked)}
        /> */}
        {/* <CheckboxInput
          name="deleteRows"
          label="Delete rows"
          checked={formik.values.deleteRows}
          onChange={(e) => formik.setFieldValue("deleteRows", e.target.checked)}
        /> */}
        {/* <CheckboxInput
          name="deleteColumns"
          label="Delete columns"
          checked={formik.values.deleteColumns}
          onChange={(e) =>
            formik.setFieldValue("deleteColumns", e.target.checked)
          }
        /> */}
        {/* <CheckboxInput
          name="errorOnValidation"
          label="Error on validation"
          checked={formik.values.errorOnValidation}
          onChange={(e) =>
            formik.setFieldValue("errorOnValidation", e.target.checked)
          }
        />*/}
        {/* <hr /> */}
        <Button variant="success" disabled={formik.isSubmitting}>
          Upload
        </Button>
      </form>
    </ContentCard>
  );
};

export default UploadDataset;
