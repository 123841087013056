"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetUploadStatus = void 0;
var assetUploadStatus;
(function (assetUploadStatus) {
    assetUploadStatus["uploading"] = "uploading";
    assetUploadStatus["uploaded"] = "uploaded";
    assetUploadStatus["failed"] = "failed";
    assetUploadStatus["paused"] = "paused";
    assetUploadStatus["cancelled"] = "cancelled";
})(assetUploadStatus = exports.assetUploadStatus || (exports.assetUploadStatus = {}));
