import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDatasets } from "../../api";
import {
  Button,
  CheckboxInput,
  ContentCard,
  Loader,
  SelectInput,
  TextInput,
} from "../../components";
import { columnType, dateFormats, IDatasetMetadata } from "@toapi/api";

const StyledContainer = styled(ContentCard)`
  max-width: 1024px;
`;

const ColumnConfigurationGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr 1fr 1fr 1fr;
  align-items: center;
  row-gap: 20px;
  column-gap: 10px;

  & > .heading {
    font-weight: 600;
  }
`;

const ConfigureDatasetColumns: React.FC = () => {
  const { updateDataset, dataset, loadingDataset, reloadDataset } =
    useDatasets();
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState<IDatasetMetadata | null>(
    dataset?.metadata ?? null
  );
  const reloadTimeout = useRef<NodeJS.Timeout | null>(null);

  const identifierColumn = metadata?.columns.find((c) => c.isIdentifier);
  const hasIdentifier = Boolean(identifierColumn);

  const shouldDisable = metadata?.status === "configured";

  const disabled = !hasIdentifier;

  useEffect(() => {
    reloadDataset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataset) {
      setMetadata(dataset.metadata);
      if (reloadTimeout.current) {
        clearTimeout(reloadTimeout.current);
      }
    }

    if (!dataset || !dataset.metadata) {
      if (reloadTimeout.current) {
        clearTimeout(reloadTimeout.current);
      }
      reloadTimeout.current = setTimeout(() => reloadDataset(), 5000);
    }
  }, [dataset, reloadDataset]);

  if (loadingDataset) return <Loader message="Loading dataset..." />;
  if (!metadata) return null;
  if (!dataset) return <div>Not found</div>;

  const onSave = async () => {
    if (hasIdentifier) {
      updateDataset({
        metadata: {
          ...metadata,
          status: "configured",
        },
      });
      navigate(`/datasets/${dataset.id}`);
    }
  };

  const onColumnChange =
    (columnId: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setMetadata({
        ...metadata,
        columns: metadata.columns.map((c) => {
          if (c.id === columnId) {
            // @ts-ignore
            c[e.target.name] =
              e.target.type === "checkbox"
                ? (e.target as HTMLInputElement).checked
                : e.target.value;
          }

          return c;
        }),
      });
    };

  return (
    <StyledContainer>
      <h3>Configure columns</h3>
      <hr />
      <ColumnConfigurationGrid>
        <>
          <span className="heading">Name</span>
          <span className="heading">Description</span>
          <span className="heading">Type</span>
          <span className="heading">Format</span>
          <span className="heading">Is identifier?</span>
          <span className="heading">Is nullable?</span>
          <span className="heading">Is searchable?</span>
        </>
        {metadata.columns.map((column) => (
          <>
            <span>
              <b>{column.name}</b> <small>({column.originalName})</small>
            </span>
            <span>
              <TextInput
                name="description"
                placeholder="Column description"
                value={column.description}
                onChange={onColumnChange(column.id)}
              />
            </span>
            <span>
              <SelectInput
                name="type"
                value={column.type ?? "text"}
                onChange={onColumnChange(column.id)}
                disabled={identifierColumn === column && shouldDisable}
              >
                {Object.keys(columnType).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </SelectInput>
            </span>
            <span>
              {["date", "datetime"].includes(column.type) && (
                <SelectInput
                  name="dateFormat"
                  value={column.dateFormat ?? "mm-dd-yyyy"}
                  onChange={onColumnChange(column.id)}
                >
                  {Object.keys(dateFormats).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </SelectInput>
              )}
            </span>
            <span>
              <CheckboxInput
                name="isIdentifier"
                checked={column.isIdentifier}
                onChange={onColumnChange(column.id)}
                disabled={
                  (identifierColumn && identifierColumn !== column) ||
                  ![
                    columnType.text,
                    columnType.uniqueidentifier,
                    columnType.number,
                  ].includes(column.type) ||
                  (identifierColumn === column && shouldDisable)
                }
              />
            </span>
            <span>
              <CheckboxInput
                name="nullable"
                checked={column.nullable}
                onChange={onColumnChange(column.id)}
                disabled={column.isIdentifier}
              />
            </span>
            <span>
              <CheckboxInput
                name="searchable"
                checked={column.searchable}
                onChange={onColumnChange(column.id)}
                disabled={column.type !== columnType.text}
              />
            </span>
          </>
        ))}
      </ColumnConfigurationGrid>
      <hr />
      <Button variant="success" disabled={disabled} onClick={onSave}>
        Save
      </Button>
    </StyledContainer>
  );
};

export default ConfigureDatasetColumns;
