export const plans = [
  {
    id: "price_1N9XqvEmuhb3XGGHtniiFyA4",
    name: "Free",
    showPaymentMethod: false,
    price: "$0",
    datasets: "3",
    imports: "100",
    rowsPerImport: "100",
    endpoints: "unlimited",
    calls: "300",
    keys: "1",
    email: false,
    technical: false,
  },
  {
    id: "price_1N7JjnEmuhb3XGGHlsRifABg",
    name: "Basic",
    showPaymentMethod: true,
    price: "$9",
    datasets: "20",
    imports: "500",
    rowsPerImport: "500",
    endpoints: "unlimited",
    calls: "10k",
    keys: "5",
    email: true,
    technical: false,
  },
  {
    id: "price_1N7JkEEmuhb3XGGHYmniK8Ig",
    name: "Professional",
    showPaymentMethod: true,
    price: "$49",
    datasets: "unlimited",
    imports: "5k",
    rowsPerImport: "50k",
    endpoints: "unlimited",
    calls: "150k",
    keys: "unlimited",
    email: true,
    technical: true,
  },
  {
    id: "price_1N7JkWEmuhb3XGGHqNUA95ea",
    name: "Plus",
    showPaymentMethod: true,
    price: "$199",
    datasets: "unlimited",
    imports: "10k",
    rowsPerImport: "500k",
    endpoints: "unlimited",
    calls: "1 million",
    keys: "unlimited",
    email: true,
    technical: true,
  },
];
