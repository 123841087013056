import styled from "styled-components";
import { mobileMediaQuery } from "../../common";

export const PaddedContainer = styled.div`
  width: 1048px;
  margin: auto;

  ${mobileMediaQuery} {
    width: 100%;
  }
`;
