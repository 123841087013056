import React from "react";
import { Button, ContentCard, TextInput } from "../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useTeam } from "../../api";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
});

const CreateATeam: React.FC = () => {
  const { createTeam, setTeam } = useTeam();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async ({ name }, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      createTeam(name)
        .then((team) => {
          if (team) {
            setTeam(team.id);
            navigate(paths.createApp);
          }
        })
        .catch((e) => {
          console.error(e);
          // TODO handle creation error
          setFieldError("name", "An error has occured. Try again.");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <ContentCard>
      <h3>Create a new team</h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="Team name"
          placeholder=""
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <hr />
        <Button variant="success">Create team</Button>
      </form>
    </ContentCard>
  );
};

export default CreateATeam;
