import React from "react";
import { BiBulb, BiFolder, BiKey, BiServer } from "react-icons/bi";
import { FaFileCsv } from "react-icons/fa";
import { TbShieldLock } from "react-icons/tb";
import styled from "styled-components";
import { PaddedContainer } from "./PaddedContainer";
import { mobileMediaQuery } from "../../common";

const Container = styled.div`
  display: grid;
  padding: 50px 0;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 30px;

  ${mobileMediaQuery} {
    grid-template-columns: repeat(1, 1fr);
    padding: 50px 20px;
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
      font-size: 1.125rem;
    }

    svg {
      font-size: 2.2rem;
      margin-right: 15px;
    }
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.8rem;
  }
`;

const data = [
  {
    title: "Multiple file types",
    description:
      "Accept csv, xslx, xsl, json and most common spreadsheet formats.",
    Icon: FaFileCsv,
  },
  {
    title: "Data security",
    description: "End to end encryption for your data at transit and at rest.",
    Icon: BiKey,
  },
  {
    title: "Organise",
    description: "Organise all of your API endpoints into separate apps.",
    Icon: BiFolder,
  },
  {
    title: "Swagger docs",
    description:
      "Fully functioning Swagger UI documentation at your finger tips.",
    Icon: BiBulb,
  },
  {
    title: "Serverless",
    description:
      "You do not have to worry about hosting or maintaining a server.",
    Icon: BiServer,
  },
  {
    title: "Endpoint security",
    description:
      "Your endpoints are secured by API keys with full CORS control.",
    Icon: TbShieldLock,
  },
];

const Benefits: React.FC = () => {
  return (
    <PaddedContainer>
      <Container>
        {data.map((item, i) => (
          <div key={i} className="box">
            <div className="top">
              <item.Icon />
              <h5>{item.title}</h5>
            </div>
            <p>{item.description}</p>
          </div>
        ))}
      </Container>
    </PaddedContainer>
  );
};

export default Benefits;
