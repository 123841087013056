import React, { useEffect, useMemo, useState } from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import ReactJson from "react-json-view";
import styled from "styled-components";
import { useEndpoints, useTeam } from "../../api";
import { themeColor } from "../../common";
import {
  Alert,
  Button,
  CheckboxInput,
  ContentCard,
  Loader,
  TextInput,
} from "../../components";
import env from "../../env";
import { getMethodRetrievalType, httpMethod, queryParamType } from "@toapi/api";
import queryString from "query-string";

const PathContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;

  & > div {
    padding: 0;
    margin: 0;

    & input {
      margin: 0;
      height: 100%;
      border-radius: 0;
      border-right: none;
      border-left: none;
      font-style: italic;
      font-weight: 500;
    }
  }

  & button {
    height: 100%;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    align-text: center;
    background: ${themeColor("background-secondary")};
    border: 0.5px solid ${themeColor("border-primary")};
    font-weight: 600;
    font-size: 0.925rem;
  }
`;

const ParamsContainer = styled.div`
  & .item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
  }
`;

const TextEndpoint: React.FC = () => {
  const { endpoint, defaultApiKey } = useEndpoints();
  const { selectedApp } = useTeam();
  const [response, setResponse] = useState<any>("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [params, setParams] = useState<Record<string, string>>({});
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [bodyParams, setBodyParams] = useState<Record<string, string>>({});

  useEffect(() => {
    if (endpoint?.params) {
      setParams(
        endpoint.params.reduce((acc, cur) => ({ ...acc, [cur.name]: "" }), {})
      );
    }
    if (endpoint?.query) {
      setQueryParams(
        endpoint.query.reduce((acc, cur) => ({ ...acc, [cur.name]: "" }), {})
      );
    }

    if (endpoint?.datasetMetadata?.columns) {
      setBodyParams(
        // @ts-ignore
        endpoint.datasetMetadata.columns.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: "" }),
          {}
        )
      );
    }
  }, [endpoint?.datasetMetadata?.columns, endpoint?.params, endpoint?.query]);

  const testUrl = useMemo(() => {
    const path = Object.entries(params).reduce(
      (ret, [key, value]) => ret.replace(key, value),
      endpoint?.path ?? ""
    );

    const url = `${env.request_function_urls.uk1}/${selectedApp?.id}${path}`;

    return queryString.stringifyUrl({ url, query: queryParams });
  }, [params, endpoint?.path, selectedApp?.id, queryParams]);

  if (!endpoint) return <Loader message="Loading endpoint..." />;

  const hasPathParams = endpoint.params && endpoint.params.length > 0;
  const hasQueryParams = endpoint.query && endpoint.query.length > 0;
  const hasParams = hasPathParams || hasQueryParams;
  const hasBody = [httpMethod.post, httpMethod.patch, httpMethod.put].includes(
    endpoint.method
  );

  const onTest = () => {
    if (Object.values(params).some((c) => !Boolean(c))) {
      setError("Params must be set");
      return;
    }

    if (
      hasQueryParams &&
      endpoint.query?.some(
        (c) =>
          c.isRequired &&
          (queryParams[c.name] === undefined || queryParams[c.name] === "")
      )
    ) {
      setError("Params must be set");
      return;
    }

    setSubmitting(true);

    const bodyData = JSON.stringify(bodyParams);

    const headers: Record<string, string> = {};

    if (hasBody) {
      headers["Content-Type"] = "application/json";
    }

    fetch(testUrl, {
      method: endpoint.method,
      headers: defaultApiKey
        ? {
            ...headers,
            "X-API-KEY": defaultApiKey?.token,
          }
        : undefined,
      body: hasBody ? bodyData : undefined,
    })
      .then((s) => {
        setSuccess(s.ok);
        setError("");
        if (!s.ok) {
          setResponse(null);
          if (s.status === 404) {
            setError(s.statusText ?? "404 - Not found");
          }
          setError(s.statusText ?? `Status Code: ${s.status}`);
          return;
        }

        return s.json();
      })
      .then(setResponse)
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <ContentCard>
        <h3>Test endpoint</h3>
        <h4>Request</h4>
        <hr />
        <PathContainer>
          <span>{endpoint.method?.toUpperCase()}</span>
          <TextInput name="request" value={endpoint.path} disabled />
          <Button variant="info" onClick={onTest} disabled={submitting}>
            Click &amp; Test API
          </Button>
        </PathContainer>
        <>
          {!hasBody && (
            <>
              <h4>Params</h4>
              <ParamsContainer>
                {endpoint.retrievalType ===
                  getMethodRetrievalType.collection && (
                  <div className="item" key="page">
                    <b>Page</b>
                    <TextInput
                      placeholder="page"
                      required={true}
                      value={queryParams["page"] ?? "1"}
                      onChange={(e) =>
                        setQueryParams({ ...queryParams, page: e.target.value })
                      }
                    />
                  </div>
                )}{" "}
                {endpoint.retrievalType ===
                  getMethodRetrievalType.collection && (
                  <div className="item" key="perPage">
                    <b>Per Page</b>
                    <TextInput
                      placeholder="per page"
                      required={true}
                      value={queryParams["perPage"] ?? "10"}
                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          perPage: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
                {endpoint.params?.map((c) => (
                  <div className="item" key={c.name}>
                    <b>{c.name}</b>
                    <TextInput
                      placeholder={c.description ?? c.name}
                      required={c.isRequired}
                      value={params[c.name]}
                      onChange={(e) =>
                        setParams({ ...params, [c.name]: e.target.value })
                      }
                    />
                  </div>
                ))}
                {endpoint.query?.map((c) => (
                  <div className="item" key={c.name}>
                    <b>{c.name}</b>
                    {c.type === queryParamType.boolean ? (
                      <CheckboxInput
                        checked={queryParams[c.name] === "true"}
                        onChange={(e) => {
                          setQueryParams({
                            ...queryParams,
                            [c.name]: `${e.target.checked}`,
                          });
                        }}
                      />
                    ) : (
                      <TextInput
                        placeholder={c.description ?? c.name}
                        required={c.isRequired}
                        value={queryParams[c.name]}
                        onChange={(e) =>
                          setQueryParams({
                            ...queryParams,
                            [c.name]: e.target.value,
                          })
                        }
                        type={c.type === queryParamType.date ? "date" : "text"}
                      />
                    )}
                  </div>
                ))}
              </ParamsContainer>
            </>
          )}
          {hasBody && (
            <>
              <h4>Body</h4>
              <ParamsContainer>
                {Object.entries(bodyParams).map(([key, value]) => (
                  <div className="item" key={key}>
                    <b>{key}</b>
                    <TextInput
                      placeholder={key}
                      required={true}
                      value={value}
                      onChange={(e) =>
                        setBodyParams({
                          ...bodyParams,
                          [key]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
              </ParamsContainer>
            </>
          )}
          <hr />
        </>
        <h4>Response</h4>
        <hr />
        {success && (
          <Alert variant="success">
            <FaCheckCircle /> <span>{testUrl}</span>
          </Alert>
        )}
        {error && (
          <Alert variant="danger">
            <FaExclamationCircle /> <span>{error}</span>
          </Alert>
        )}
        {response && <ReactJson name={false} src={response} indentWidth={2} />}
      </ContentCard>
    </>
  );
};

export default TextEndpoint;
