import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TestEndpoint } from ".";
import { useEndpoints, useTeam } from "../../api";
import { ContentCard } from "../../components";
import env from "../../env";

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 25px;

  & a {
    display: block;
    margin: 5px 0;
  }

  & > div {
    width: 100%;
    max-width: 100%;
  }
`;

const ViewEndpoint: React.FC = () => {
  const { selectedApp } = useTeam();
  const { endpoint } = useEndpoints();

  return (
    <Container>
      <ContentCard>
        <h3>{`${endpoint?.name}`}</h3>
        <hr />
        <TestEndpoint />
      </ContentCard>
      <ContentCard>
        <h3>Settings</h3>
        <hr />
        {/* <Link to="test">Try it out</Link> */}
        <Link to="configure">Configure</Link>
        <Link
          to={`/${selectedApp?.id}/swagger`}
          target="_blank"
          rel="noreferrer"
        >
          Swagger docs
        </Link>
      </ContentCard>
    </Container>
  );
};

export default ViewEndpoint;
