import React from "react";
import { useEndpoints } from "../../api";
import { Button, ContentCard, SelectInput, TextInput } from "../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import { getMethodRetrievalType, httpMethod } from "@toapi/api";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
  path: yup.string().required(),
  method: yup.mixed().oneOf(["get", "post", "put", "patch", "delete"]),
  datasetId: yup.string().required(),
  retrievalType: yup.mixed().oneOf(["collection", "item"]),
});

const CreateEndpoint: React.FC = () => {
  const navigate = useNavigate();
  const { createEndpoint, datasets } = useEndpoints();

  const formik = useFormik({
    initialValues: {
      name: "",
      path: "",
      method: "get",
      datasetId: "",
      retrievalType: "collection",
    },
    validationSchema,
    onSubmit: async (
      { name, path, method, retrievalType, datasetId },
      { setSubmitting, setFieldError }
    ) => {
      setSubmitting(true);
      try {
        const id = await createEndpoint(
          name,
          path,
          method as httpMethod,
          retrievalType as getMethodRetrievalType,
          datasetId
        );
        navigate(`/endpoints/${id}`);
      } catch (e) {
        console.log(e);
        // TODO handle error
        setFieldError("name", "An error occured. Try again");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onUpdateEndpointPath = (path: string) => {
    if (!path.startsWith("/")) {
      path = `/${path}`;
    }

    path = path.replace(" ", "-");
    const regex = /(-)\1+/i;
    path = path.replace(regex, "-");

    if (path.startsWith("/-")) {
      path = path.replace("/-", "/");
    }

    formik.setFieldValue("path", path);
  };

  const onEndpointNameChange = (value: string) => {
    const options = { lower: true };

    const prevSlug = `/${slugify(formik.values.name, options)}`;
    const slug = `/${slugify(value, options)}`;
    const { path } = formik.values;

    if (path === "" || path === "/" || path === prevSlug) {
      formik.setFieldValue("path", slug);
    }
  };

  return (
    <ContentCard>
      <h3>Create an endpoint</h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="Endpoint name"
          placeholder=""
          value={formik.values.name}
          onChange={(e) => {
            onEndpointNameChange(e.target.value);
            formik.handleChange(e);
          }}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <TextInput
          name="path"
          label="Endpoint path"
          placeholder="i.e. '/users'"
          value={formik.values.path}
          onChange={(e) => onUpdateEndpointPath(e.target.value)}
          touched={formik.touched.path}
          error={formik.errors.path}
          note="You can add path params i.e. /users/:id"
        />
        <SelectInput
          name="method"
          label="Method"
          value={formik.values.method}
          onChange={formik.handleChange}
        >
          <option value="get">GET</option>
          <option value="post">POST</option>
          <option value="patch" disabled>
            PATCH (Coming soon)
          </option>
          <option value="put" disabled>
            PUT (Coming soon)
          </option>
          <option value="delete"></option>
        </SelectInput>
        {formik.values.method === "get" && (
          <SelectInput
            name="retrievalType"
            label="Get type (Collections vs Specific Item)"
            value={formik.values.retrievalType}
            onChange={formik.handleChange}
          >
            <option value="collection">Entire Collection</option>
            <option value="item">Specific Item</option>
          </SelectInput>
        )}
        <SelectInput
          name="datasetId"
          label="Dataset"
          value={formik.values.datasetId}
          onChange={formik.handleChange}
        >
          <option></option>
          {datasets.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </SelectInput>
        <hr />
        <Button variant="success">Create endpoint</Button>
      </form>
    </ContentCard>
  );
};

export default CreateEndpoint;
