import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { themeColor } from "../../../common";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: unset;
  padding: 10px 5px;
  &:hover {
    background-color: ${themeColor("background-secondary")};
  }
`;

interface Props {
  name: string;
  to: string;
}

const HomeCardItem: React.FC<Props> = ({ name, to }) => {
  return (
    <Link to={to}>
      <Container>
        <span>{name}</span>
        <FaArrowRight />
      </Container>
    </Link>
  );
};

export default HomeCardItem;
