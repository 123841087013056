"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateFormats = exports.columnType = exports.datasetImportStatus = exports.datasetStatus = exports.datasetType = void 0;
var datasetType;
(function (datasetType) {
    datasetType["csv"] = "csv";
    datasetType["json"] = "json";
    datasetType["excel"] = "excel";
})(datasetType = exports.datasetType || (exports.datasetType = {}));
var datasetStatus;
(function (datasetStatus) {
    datasetStatus["created"] = "created";
    datasetStatus["inactive"] = "inactive";
    datasetStatus["active"] = "active";
    datasetStatus["uploading"] = "uploading";
    datasetStatus["error"] = "error";
})(datasetStatus = exports.datasetStatus || (exports.datasetStatus = {}));
var datasetImportStatus;
(function (datasetImportStatus) {
    datasetImportStatus["paused"] = "paused";
    datasetImportStatus["uploading"] = "uploading";
    datasetImportStatus["uploaded"] = "uploaded";
    datasetImportStatus["failed"] = "failed";
})(datasetImportStatus = exports.datasetImportStatus || (exports.datasetImportStatus = {}));
var columnType;
(function (columnType) {
    columnType["text"] = "text";
    columnType["date"] = "date";
    columnType["datetime"] = "datetime";
    columnType["number"] = "number";
    columnType["boolean"] = "boolean";
    columnType["uniqueidentifier"] = "uniqueidentifier";
})(columnType = exports.columnType || (exports.columnType = {}));
var dateFormats;
(function (dateFormats) {
    dateFormats["yyyy-MM-dd"] = "yyyy-MM-dd";
    dateFormats["yyyy/MM/dd"] = "yyyy/MM/dd";
    dateFormats["dd-MM-yyyy"] = "dd-MM-yyyy";
    dateFormats["dd/MM/yyyy"] = "dd/MM/yyyy";
    dateFormats["yyyy-MM-dd HH:mm"] = "yyyy-MM-dd HH:mm";
    dateFormats["yyyy/MM/dd HH:mm"] = "yyyy/MM/dd HH:mm";
    dateFormats["dd-MM-yyyy HH:mm"] = "dd-MM-yyyy HH:mm";
    dateFormats["dd/MM/yyyy HH:mm"] = "dd/MM/yyyy HH:mm";
    dateFormats["yyyy-MM-dd HH:mm:ss"] = "yyyy-MM-dd HH:mm:ss";
    dateFormats["yyyy/MM/dd HH:mm:ss"] = "yyyy/MM/dd HH:mm:ss";
    dateFormats["dd-MM-yyyy HH:mm:ss"] = "dd-MM-yyyy HH:mm:ss";
    dateFormats["dd/MM/yyyy HH:mm:ss"] = "dd/MM/yyyy HH:mm:ss";
    dateFormats["yyyy-MM-ddTHH:mm"] = "yyyy-MM-ddTHH:mm";
    dateFormats["yyyy/MM/ddTHH:mm"] = "yyyy/MM/ddTHH:mm";
    dateFormats["dd-MM-yyyyTHH:mm"] = "dd-MM-yyyyTHH:mm";
    dateFormats["dd/MM/yyyyTHH:mm"] = "dd/MM/yyyyTHH:mm";
    dateFormats["yyyy-MM-ddTHH:mm:ss"] = "yyyy-MM-ddTHH:mm:ss";
    dateFormats["yyyy/MM/ddTHH:mm:ss"] = "yyyy/MM/ddTHH:mm:ss";
    dateFormats["dd-MM-yyyyTHH:mm:ss"] = "dd-MM-yyyyTHH:mm:ss";
    dateFormats["dd/MM/yyyyTHH:mm:ss"] = "dd/MM/yyyyTHH:mm:ss";
})(dateFormats = exports.dateFormats || (exports.dateFormats = {}));
