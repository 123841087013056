import { assetUploadStatus, IDatasetHistoricalUploadSimple } from "@toapi/api";
import React from "react";
import { useDatasets } from "../../api";
import { Badge, Card, Table, Toolbar } from "../../components";

const status: Record<
  assetUploadStatus,
  "success" | "read" | "danger" | "info" | "warning"
> = {
  cancelled: "warning",
  uploaded: "success",
  failed: "danger",
  paused: "warning",
  uploading: "info",
};

const DatasetUploadHistory: React.FC = () => {
  const { useDatasetResource, dataset } = useDatasets();
  const [records] = useDatasetResource<IDatasetHistoricalUploadSimple>(
    `uploads`,
    dataset?.id,
    true
  );

  if (!dataset) return null;

  return (
    <div>
      <Toolbar>
        <h3>Upload History</h3>
        <div className="spacer" />
      </Toolbar>
      <Card>
        <div>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Uploaded on</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.assetId}>
                  <td title={record.assetId}>{record.assetId.slice(-5)}</td>
                  <td title={record.createdAt.toString()}>
                    {new Date(record.createdAt).toDateString()}
                  </td>
                  <td>
                    <Badge variant={status[record.status]}>
                      {record.status}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default DatasetUploadHistory;
