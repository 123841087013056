import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Router } from "./routes";
import { theme } from "./common";
import {
  AuthProvider,
  SocketSubscriber,
  TeamsProvider,
  ToastProvider,
} from "./api";

document.title = "apiGO Dashboard";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <AuthProvider>
          <SocketSubscriber>
            <TeamsProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </TeamsProvider>
          </SocketSubscriber>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
