import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { Loader } from "../components";
import {
  datasetType,
  ICreateDatasetRequest,
  IDataset,
  IDatasetSimple,
  IDefaultEndpoints,
} from "@toapi/api";
import { useTeam } from "./team";
import { UseResourceResponseType } from "../common";
import { eventsToSubscribe, useSocketSubscriber } from "./socketsbuscriber";

interface DatasetsContextState {
  datasets: IDatasetSimple[];
  dataset: IDataset | null;
  loadingDataset: boolean;
  createDataset(
    name: string,
    type: datasetType,
    defaultEndpoints: IDefaultEndpoints
  ): Promise<string | null>;
  uploadDataset(file: File | null): Promise<void>;
  updateDataset(dataset: Partial<Omit<IDataset, "id">>): Promise<void>;
  reloadDataset(): void;
  deleteDataset(id: string): Promise<void>;
  datasetData: any[];
  useDatasetResource<A>(
    resource: string,
    id?: string,
    initialLoad?: boolean
  ): UseResourceResponseType<A>;
}

const defaultDatasetsContextState: DatasetsContextState = {
  datasets: [],
  dataset: null,
  loadingDataset: false,
  createDataset: () => Promise.resolve(""),
  uploadDataset: () => Promise.resolve(),
  updateDataset: () => Promise.resolve(),
  reloadDataset: () => null,
  deleteDataset: () => Promise.resolve(),
  datasetData: [],
  // @ts-ignore
  useDatasetResource: () => {},
};

const DatasetsContext = createContext<DatasetsContextState>(
  defaultDatasetsContextState
);

export const useDatasets = () => useContext(DatasetsContext);

export const DatasetsProvider: React.FC = () => {
  const { selectedApp, useAppResource, initialised } = useTeam();
  const [datasets, loading, { refresh: refreshDatasets }, datasetsApi] =
    useAppResource<IDatasetSimple>("datasets", selectedApp?.id);
  const { id } = useParams();
  const [dataset, setDataset] = useState<IDataset | null>(null);
  const loadingDataset = useRef(false);
  const [datasetData, , { refresh: refreshData }] =
    datasetsApi.useResource<any>(`data`, id, true);
  const { subscribe } = useSocketSubscriber();

  useEffect(() => {
    const unsubscribe = subscribe(
      eventsToSubscribe.onDatasetFileUploaded,
      async (message: any) => {
        console.log("got message", message);
        await refreshDatasets();
        if (id) {
          getDataset();
          await refreshData();
        }
      }
    );

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp, id]);

  const getDataset = useCallback(() => {
    if (!selectedApp || !initialised) return;
    if (loadingDataset.current) {
      return;
    }
    console.log("calling get dataset");

    if (!id) {
      setDataset(null);
      loadingDataset.current = false;
    } else {
      loadingDataset.current = true;

      datasetsApi
        .get<IDataset>({
          path: id,
        })
        .then(setDataset)
        .finally(() => {
          loadingDataset.current = false;
        });
    }
  }, [datasetsApi, id, selectedApp, initialised]);

  useEffect(() => {
    if (id && dataset?.id !== id) {
      getDataset();
    }
  }, [dataset?.id, getDataset, id]);

  const createDataset = async (
    request: ICreateDatasetRequest
  ): Promise<string | null> => {
    const id = await datasetsApi.post<string>({
      path: "",
      body: request,
    });

    await refreshDatasets();

    return id;
  };

  const deleteDataset = async (id: string) => {
    await datasetsApi.delete({
      path: id,
    });
    await refreshDatasets();
  };

  if (loading) return <Loader message="Loading datasets..." />;

  const updateDataset = async (data: Partial<Omit<IDataset, "id">>) => {
    await datasetsApi.put({
      path: id,
      body: data,
    });

    await refreshDatasets();
  };

  const onUploadDataset = async (file: File) => {
    const data = new FormData();
    data.set("file", file);

    await datasetsApi.post({
      path: `${id}/upload`,
      body: data,
    });

    await refreshDatasets();
  };

  return (
    <DatasetsContext.Provider
      value={{
        datasets,
        dataset,
        loadingDataset: loadingDataset.current,
        createDataset: (
          name: string,
          importType: datasetType,
          defaultEndpoints: IDefaultEndpoints
        ) =>
          createDataset({
            name,
            importType,
            defaultEndpoints,
          }),
        uploadDataset: onUploadDataset,
        updateDataset,
        reloadDataset: getDataset,
        deleteDataset,
        datasetData,
        useDatasetResource: datasetsApi.useResource,
      }}
    >
      <Outlet />
    </DatasetsContext.Provider>
  );
};
