import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loadTokens } from "../api";
import LogoImg from "../assets/logo.png";
import { mobileMediaQuery, themeColor, useBreakpoints } from "../common";
import { Button } from "../components";
import MainFooter from "./MainFooter";
import MainHamburgerMenu from "./MainHamburgerMenu";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  align-items: center;

  width: 100%;
  margin: auto;

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const Spacer = styled.span`
  flex-grow: 1;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${themeColor("background-primary")};
  font-weight: bold;
  margin: 0 20px;

  &:hover {
    color: ${themeColor("read")};
  }
`;

const StyledButton = styled(Button)`
  margin: 0 5px;
`;

const Main = styled.main`
  ${mobileMediaQuery} {
    width: 100%;
  }
`;

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const { mobile } = useBreakpoints();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const { token } = loadTokens();

    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) return;

    window.scrollTo({
      top: el.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <NavBar>
        <Link to="/">
          <Logo src={LogoImg} />
        </Link>
        <Spacer />
        {mobile && <MainHamburgerMenu />}
        {!mobile && (
          <>
            <NavLink to="" onClick={() => scrollTo("features")}>
              Features
            </NavLink>
            <NavLink to="" onClick={() => scrollTo("pricing")}>
              Pricing
            </NavLink>
            <NavLink to="#">Demo</NavLink>
            <NavLink to="#">Docs</NavLink>
            <NavLink to="/marketplace">Marketplace</NavLink>
            {!isLoggedIn ? (
              <>
                <StyledButton
                  variant="read"
                  onClick={() => navigate("/signup")}
                >
                  Register
                </StyledButton>
                <StyledButton variant="read" onClick={() => navigate("/login")}>
                  Login
                </StyledButton>
              </>
            ) : (
              <StyledButton
                variant="read"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </StyledButton>
            )}
          </>
        )}
      </NavBar>
      <Main>
        <Outlet />
      </Main>
      <MainFooter />
    </Container>
  );
};

export default MainLayout;
