import { useState, useEffect } from "react";
import { useTheme } from "styled-components";

export const breakpoints = {
  mobile: 768,
  tablet: 1024,
};

const light = {
  "background-primary": "#1e2025",
  "background-secondary": "#ebecef",
  "background-tertiary": "#f9fafb",
  "background-quaternary": "#f1f2f4",
  "text-primary": "#40444f",
  "text-secondary": "#a5aab6",
  "text-tertiary": "#898f9f",
  "link-primary": "#2d77aa",
  "border-primary": "#dddfe4",
  success: "#60aa1b",
  read: "#3e92cc", // TODO try #0080ff
  danger: "#d34638",
  info: "#deb841",
  pending: "#7e4999",
  warning: "#f48535",
  white: "#fff",
};

export const colors = light;

export interface Theme {
  colors: typeof colors;
  breakpoints: typeof breakpoints;
}

declare module "styled-components" {
  interface DefaultTheme extends Theme {}
}

export const useBreakpoints = () => {
  const { breakpoints } = useTheme();

  const [breakpointState, setBreakpointState] = useState<{
    mobile: boolean;
    tablet: boolean;
  }>(
    Object.entries(breakpoints).reduce(
      (rv, curr) => ({
        ...rv,
        [curr[0]]: false,
      }),
      { mobile: false, tablet: false }
    )
  );

  useEffect(() => {
    const subscriptions: (() => void)[] = [];

    for (const breakpoint of Object.entries(breakpoints)) {
      const match = window.matchMedia(`(max-width: ${breakpoint[1]}px)`);

      const handler = (state: { matches: boolean }) => {
        setBreakpointState((prev) => ({
          ...prev,
          [breakpoint[0]]: state.matches,
        }));
      };

      match.addListener(handler);
      handler(match);

      subscriptions.push(() => match.removeListener(handler));
    }
  }, [breakpoints]);

  return breakpointState;
};

export interface ThemeProps {
  theme: Theme;
}

export const mobileMediaQuery = ({ theme }: ThemeProps) =>
  `@media (max-width: ${theme.breakpoints.mobile}px)`;

export const tabletMediaQuery = ({ theme }: ThemeProps) =>
  `@media (max-width: ${theme.breakpoints.tablet}px)`;

type ColorName = keyof typeof colors;

export const themeColor =
  (color: ColorName) =>
  ({ theme }: ThemeProps) =>
    theme.colors[color];

interface BoxShadowProps {
  offset: number;
  blur: number;
  opacity: number;
  spread: number;
  color: string;
}

export const boxShadow = ({
  offset = 0,
  blur = 2,
  opacity = 0.25,
  spread = 0,
  color,
}: Partial<BoxShadowProps> = {}) => {
  return `box-shadow: ${offset}px ${offset}px ${blur}px ${spread}px ${
    color ?? `rgba(0, 0, 0, ${opacity})`
  }`;
};

export const theme: Theme = {
  colors,
  breakpoints,
};
