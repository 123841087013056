import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import env from "../../env";

document.title = "Loading... - Swagger Docs";

const Swagger: React.FC = () => {
  const { id } = useParams();

  useEffect(() => {
    document.title = "Swagger Docs";
  }, []);

  return (
    <SwaggerUI
      url={`${env.api}/${id}/swagger/swagger.json`}
      onComplete={(doc) => {
        document.title = `${
          JSON.parse(doc.spec().get("spec")).info.title
        } - Swagger Docs`;
      }}
    />
  );
};

export default Swagger;
