import React from "react";
import styled from "styled-components";
import { mobileMediaQuery, themeColor } from "../../common";
import { PaddedContainer } from "./PaddedContainer";
import howItWorks1 from "./Images/how-it-works-01.png";
import howItWorks2 from "./Images/how-it-works-02.png";
import howItWorks3 from "./Images/how-it-works-03.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${themeColor("read")};
  padding: 50px 0;
  color: ${themeColor("white")};

  h4 {
    text-align: center;
    margin: 20px 0;
    margin-bottom: 50px;
    font-size: 1.6rem;
  }
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${mobileMediaQuery} {
    display: grid;
    flex-direction: column;
    text-align: center;
  }

  .box {
    width: 300px;

    ${mobileMediaQuery} {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 10px;
    }

    img {
      width: 300px;

      ${mobileMediaQuery} {
        width: 100%;
        border-radius: 5px;
      }
    }

    h5 {
      font-size: 1.125rem;
      margin-top: 20px;
      margin-bottom: 10px;

      ${mobileMediaQuery} {
        font-size: 1.25rem;
      }
    }

    span {
      font-size: 1rem;
    }
  }
`;

const data = [
  {
    image: howItWorks1,
    heading: "1 — Upload your CSV, JSON or Excel file",
    description:
      "Give your dataset a name, configure its columns and click on create",
  },
  {
    image: howItWorks2,
    heading: "2 — Configure the endpoints you want to create",
    description:
      "Add any parameters and filters to your endpoint and test it out",
  },
  {
    image: howItWorks3,
    heading: "3 — Start using your API",
    description:
      "Your API is ready to use. Grab your API key and start creating",
  },
];

const HowDoesItWork: React.FC = () => {
  return (
    <Container>
      <PaddedContainer>
        <h4>How does it work?</h4>
        <FlowContainer>
          {data.map((d, i) => (
            <div className="box">
              <img key={i} alt={d.heading} src={d.image} />
              <h5>{d.heading}</h5>
              <span>{d.description}</span>
            </div>
          ))}
        </FlowContainer>
      </PaddedContainer>
    </Container>
  );
};

export default HowDoesItWork;
