import React, { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { themeColor } from "../common";
import { FaTimes } from "react-icons/fa";
import { Button } from "./Button";

export interface ModalProps extends PropsWithChildren {
  title?: string;
  open: boolean;
  onClose?(): void;
  danger?: string;
  success?: string;
  onDanger?(): void;
  onSuccess?(): void;
  className?: "";
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: ${themeColor("background-quaternary")};
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid ${themeColor("border-primary")};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
    margin-left: 10px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 15px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin: 20px 0;
`;

export const SimpleModal: React.FC<ModalProps> = ({
  title,
  open,
  onClose,
  danger,
  success,
  onDanger,
  onSuccess,
  className,
  children,
}) => {
  return (
    <StyledModal isOpen={open} onRequestClose={onClose}>
      {title && (
        <>
          <ModalHeader>
            <h4>{title}</h4>
            <Button
              variant="light"
              onClick={() => (onClose ? onClose() : undefined)}
            >
              <FaTimes />
            </Button>
          </ModalHeader>
          <hr />
        </>
      )}
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        {danger && (
          <Button variant="danger" onClick={onDanger}>
            {danger}
          </Button>
        )}
        {success && (
          <Button variant="success" onClick={onSuccess}>
            {success}
          </Button>
        )}
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export const useModalState = (defaultOpen: boolean = false) => {
  const [open, setOpen] = useState(defaultOpen);
  const [ref, setRef] = useState<string | null>(null);

  const handleOpen = (ref: any | undefined = undefined) => {
    if (ref !== undefined && typeof ref === "string") {
      setRef(ref);
    } else {
      setRef(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setRef(null);
    setOpen(false);
  };

  return {
    open,
    ref,
    onOpen: handleOpen,
    onClose: handleClose,
  };
};
