import jwtDecode from "jwt-decode";
import { StringifiableRecord, stringifyUrl } from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../api";
import env from "../env";
import { useLoading } from "./loading";

export type HttpMethod = "GET" | "POST" | "OPTION" | "PUT" | "DELETE";

export type RequestBody =
  | string
  | Blob
  | FormData
  | null
  | undefined
  | URLSearchParams;

interface RequestOptions {
  body?: RequestBody;
  token?: string | null;
  method?: HttpMethod;
  refreshAccessToken?: (() => Promise<string | null>) | null;
}

let accessTokenRefreshPromise: Promise<string | null> | null = null;

const EXPIRES_IN_MILISECONDS = 5_000; // 5 seconds

export const makeApiRequest = async <T>(
  url: string,
  {
    body,
    token = null,
    method = "GET",
    refreshAccessToken = null,
  }: RequestOptions = {}
) => {
  const headers = new Headers();

  if (token !== null) {
    headers.append("Authorization", `Bearer ${token}`);
    if (refreshAccessToken) {
      let tokenValid = false;
      let exp = 0;

      try {
        const decoded = jwtDecode<{ exp: number }>(token);
        exp = decoded.exp;
        tokenValid = true;
      } catch {
        tokenValid = false;
      }
      if (!tokenValid || Date.now() + EXPIRES_IN_MILISECONDS >= exp * 1000) {
        if (accessTokenRefreshPromise === null) {
          accessTokenRefreshPromise = refreshAccessToken();
        }

        const newToken = await accessTokenRefreshPromise;
        accessTokenRefreshPromise = null;

        if (newToken !== null) {
          headers.set("Authorization", `Bearer ${newToken}`);
        }
      }
    }
  }
  if (typeof body === "string") {
    headers.append("Content-Type", "application/json");
  }

  // TODO make sure url comes from config
  const response = await fetch(`${env.api}${url}`, {
    method,
    body,
    headers,
  });

  if (!response.ok) {
    if (response.status === 404) {
      return null;
    }

    throw new Error(response.statusText);
  }

  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    return (await response.json()) as T;
  } else {
    return (await response.blob()) as unknown as T;
  }
};

export type UseResourceResponseType<T> = [
  T[],
  boolean,
  { refresh(): Promise<void> },
  {
    get<D>(params: { path?: string; qp?: any }): Promise<D | null>;
    delete<D>(params: { path?: string; qp?: any }): Promise<D | null>;
    post<D>(params: {
      path?: string;
      body?: string | Record<string, any> | FormData;
      qp?: any;
    }): Promise<D | null>;
    put<D>(params: {
      path?: string;
      body?: string | Record<string, any>;
      qp?: any;
    }): Promise<D | null>;
    useResource<A>(
      resource: string,
      id?: string,
      initialLoad?: boolean
    ): UseResourceResponseType<A>;
  }
];

export const useResource = <T>(
  resource?: string,
  initialLoad: boolean = true
): UseResourceResponseType<T> => {
  const { token } = useAuth();
  const { loading, setLoaded, setLoading } = useLoading(initialLoad);
  const [resources, setResources] = useState<T[]>([]);

  const get = useCallback(
    <D>({
      path = "",
      queryParams,
    }: {
      path?: string;
      queryParams?: StringifiableRecord;
    } = {}) =>
      makeApiRequest<D>(
        stringifyUrl({ url: `/${resource}/${path}`, query: queryParams }),
        {
          method: "GET",
          token,
        }
      ),
    [resource, token]
  );

  const post = useCallback(
    <D>({
      path,
      body,
      queryParams,
    }: {
      path?: string;
      body?: string | Record<string, any> | FormData;
      queryParams?: StringifiableRecord;
    } = {}) => {
      const parsedBody =
        typeof body === "string" || body instanceof FormData
          ? body
          : JSON.stringify(body);

      return makeApiRequest<D>(
        stringifyUrl({ url: `/${resource}/${path ?? ""}`, query: queryParams }),
        {
          method: "POST",
          body: parsedBody,
          token,
        }
      );
    },
    [resource, token]
  );

  const put = useCallback(
    <D>({
      path,
      body,
      queryParams,
    }: {
      path?: string;
      body?: string | Record<string, any>;
      queryParams?: StringifiableRecord;
    } = {}) => {
      const parsedBody = typeof body === "string" ? body : JSON.stringify(body);

      return makeApiRequest<D>(
        stringifyUrl({ url: `/${resource}/${path ?? ""}`, query: queryParams }),
        {
          method: "PUT",
          body: parsedBody,
          token,
        }
      );
    },
    [resource, token]
  );
  const d = useCallback(
    <D>({
      path,
      body,
      queryParams,
    }: {
      path?: string;
      body?: string | Record<string, any>;
      queryParams?: StringifiableRecord;
    } = {}) => {
      const parsedBody = typeof body === "string" ? body : JSON.stringify(body);

      return makeApiRequest<D>(
        stringifyUrl({ url: `/${resource}/${path ?? ""}`, query: queryParams }),
        {
          method: "DELETE",
          body: parsedBody,
          token,
        }
      );
    },
    [resource, token]
  );

  const loadData = useCallback(async () => {
    setLoading();
    get<T[]>()
      .then((response) => {
        setResources(response ?? []);
      })
      .finally(() => {
        setLoaded();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get]);

  useEffect(() => {
    if (!token) return;
    if (!resource) return;

    if (initialLoad) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, loadData, token]);

  return [
    resources,
    loading,
    { refresh: loadData },
    {
      get,
      post,
      put,
      delete: d,
      useResource: (r: string, id?: string, initialLoad: boolean = true) =>
        useResource(
          id && resource ? `${resource}/${id}/${r}` : undefined,
          initialLoad
        ),
    },
  ];
};
