import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobileMediaQuery, themeColor } from "../common";
import env from "../env";

const Container = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1048px;
  margin: 25px auto;

  ${mobileMediaQuery} {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 10px 0;
    text-align: center;
  }

  b {
    color: ${themeColor("text-primary")};
  }
`;

const Sections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
  }

  & > div {
    display: flex;
    flex-direction: column;

    ${mobileMediaQuery} {
      margin: 20px 0;
    }

    h6 {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 20px;
      color: ${themeColor("text-primary")};

      ${mobileMediaQuery} {
        margin-bottom: 10px;
      }
    }

    a {
      color: ${themeColor("read")};
      text-decoration: none;
      margin: 5px 0;
    }
  }
`;

const MainFooter: React.FC = () => {
  return (
    <Container>
      <b>© 2022-{new Date().getFullYear()} apiGO.dev. All rights reserved.</b>
      <Sections>
        <div>
          <h6>Product</h6>
          <Link to="/#features">Features</Link>
          <Link to="/#pricing">Pricing</Link>
          <a href={`${env.docs_url}/docs/changelog`}>Change Log</a>
        </div>
        <div>
          <h6>Links</h6>
          <a href={`${env.docs_url}`}>Tutorials</a>
          <a href={`${env.docs_url}`}>Documentation</a>
          <Link to="">Marketplace</Link>
          <a href={`${env.docs_url}/blog`}>Blog</a>
        </div>
        <div>
          <h6>Company</h6>
          <a href={`${env.docs_url}/docs/resources/about`}>About</a>
          <a href={`${env.docs_url}/docs/resources/terms-of-service`}>
            Terms of Service
          </a>
          <a href={`${env.docs_url}/docs/resources/privacy-policy`}>
            Privacy Policy
          </a>
          <a href={`${env.docs_url}/docs/resources/gdpr-policy`}>GDPR Policy</a>
        </div>
      </Sections>
    </Container>
  );
};

export default MainFooter;
