import React from "react";
import { ViewApp } from "../Apps";

const Home: React.FC = () => {
  return <ViewApp />;

  // return (
  //   <>
  //     <h3>Colors</h3>
  //     <Card>
  //       <p>Where possible all colours should be from the colour palette.</p>
  //       <hr />
  //       <Button variant="success">Button</Button>
  //       <br />
  //       <Button variant="info">Button</Button>
  //       <br />
  //       <Button variant="read">Button</Button>
  //       <br />
  //       <Button variant="danger">Button</Button>
  //       <br />
  //       <Button variant="pending">Button</Button>
  //       <br />
  //       <Button variant="warning">Button</Button>
  //       <br />
  //       <Button variant="dark">Button</Button>
  //       <br />
  //       <Button variant="light">Button</Button>
  //       <br />
  //       <Button variant="light">
  //         Button
  //         <FiArrowLeft />
  //       </Button>
  //     </Card>
  //   </>
  // );
};

export default Home;
