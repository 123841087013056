import React, {
  HTMLInputTypeAttribute,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { themeColor } from "../common";
import { Button } from "./Button";
import { Progress } from "./Progress";
import { FormikErrors, FormikTouched } from "formik";

interface InputProps {
  label?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute | undefined;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  value?: string;
  inline?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement & HTMLTextAreaElement>;
  disabled?: boolean;
  note?: string;
}

const Container = styled.div<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  margin: 10px 0;
`;

const Note = styled.label`
  font-size: 0.795rem;
  color: ${themeColor("text-primary")};
  font-weight: 500;
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-size: 0.825rem;
  font-weight: 600;
  color: ${themeColor("text-tertiary")};
  min-width: 50px;
`;

const Input = styled.input<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  margin: 5px 0;
  width: 100%;
  padding: 10px;
  background-color: ${themeColor("background-quaternary")};
  border: 1px solid ${themeColor("border-primary")};
  color: ${themeColor("text-primary")};
  font-size: 0.925rem;
  font-weight: 500;
  border-radius: 2px;
  &:focus {
    outline-color: ${themeColor("success")};
  }
`;

const StyledTextArea = styled.textarea<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  margin: 5px 0;
  width: 100%;
  padding: 10px;
  background-color: ${themeColor("background-quaternary")};
  border: 1px solid ${themeColor("border-primary")};
  color: ${themeColor("text-primary")};
  font-size: 0.925rem;
  font-weight: 500;
  border-radius: 2px;
  &:focus {
    outline-color: ${themeColor("success")};
  }
`;

export const ErrorMessage = styled.span`
  display: inline-block;
  color: ${themeColor("danger")};
  margin: 0px 2px 0;
  font-size: 0.825rem;
  font-weight: 600;
`;

export const TextInput: React.FC<InputProps> = ({
  label,
  placeholder,
  name,
  required = false,
  type,
  touched,
  error,
  value,
  inline,
  onChange,
  disabled,
  note,
}) => {
  return (
    <Container inline={inline}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required ? "*" : ""}
        </Label>
      )}
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        inline={inline}
        disabled={disabled}
      />
      {note && <Note>{note}</Note>}
      {touched && error && <ErrorMessage>{error as string}</ErrorMessage>}
    </Container>
  );
};

interface SelectProps {
  name?: string;
  label?: string;
  value?: string;
  required?: boolean;
  flat?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  className?: string;
}

const Select = styled.select<SelectProps>`
  display: block;
  margin: 5px 0;
  width: 100%;
  padding: 10px;
  border: 1px solid ${themeColor("border-primary")};
  color: ${themeColor("text-primary")};
  font-size: 0.925rem;
  font-weight: 500;
  border-radius: 2px;
  border-left: none;
  border-right: none;
  padding-right: 10px;
  ${(props) => (props.flat ? "border-radius: unset;" : "")}
  &:focus {
    outline: 0;
  }
`;

export const SelectInput: React.FC<PropsWithChildren<SelectProps>> = ({
  name,
  label,
  required,
  value,
  flat,
  onChange,
  disabled,
  children,
  className,
}) => {
  return (
    <Container className={className}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required ? "*" : ""}
        </Label>
      )}
      <Select
        id={name}
        name={name}
        value={value}
        flat={flat}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </Select>
    </Container>
  );
};

interface CheckboxProps {
  label?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const CheckContainer = styled(Container)`
  display: flex;
  flex-direction: row;
`;

const Check = styled.input`
  accent-color: ${themeColor("read")};
  margin-left: 10px;
`;

export const CheckboxInput: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <CheckContainer>
      {label && <Label>{label}</Label>}
      <Check
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </CheckContainer>
  );
};

const FileUploadContainer = styled(Container)`
  & small {
    font-weight: 600;
    font-size: 0.725rem;
    color: ${themeColor("info")};
  }
`;

const FileUploadButton = styled(Button)`
  margin: 5px 0;
  padding: 5px;
`;

interface FileUploadProps {
  label?: string;
  onChange?(file: File): void;
  progress?: number;
}

export const FileUploadInput: React.FC<FileUploadProps> = ({
  label,
  onChange,
  progress,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  const onClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const fileUploaded = e.target.files[0];
      console.log(fileUploaded);

      setFile(fileUploaded);
      if (onChange) {
        onChange(fileUploaded);
      }
    }
  };

  const status = useMemo(() => {
    if (!file) return;
    switch ("" as any) {
      case "success":
        return `Successfully uploaded ${file.name}`;
      case "paused":
        return `Upload ${file.name}`;
      case "running":
        return `Uploading ${file.name}`;
      default:
        return null;
    }
  }, [file]);

  return (
    <FileUploadContainer>
      {label && <Label>{label}</Label>}
      <input
        ref={hiddenFileInput}
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <FileUploadButton variant="light" onClick={onClick}>
        Select a file
      </FileUploadButton>
      {status && <small>{status}</small>}
      {progress && <Progress percent={progress} />}
    </FileUploadContainer>
  );
};

export const TextArea: React.FC<InputProps> = ({
  label,
  placeholder,
  name,
  required = false,
  touched,
  error,
  value,
  inline,
  onChange,
  disabled,
  note,
}) => {
  return (
    <Container inline={inline}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {required ? "*" : ""}
        </Label>
      )}
      <StyledTextArea
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inline={inline}
        rows={8}
      />
      {note && <Note>{note}</Note>}
      {touched && error && <ErrorMessage>{error as string}</ErrorMessage>}
    </Container>
  );
};
