import React from "react";
import { useAuth, useTeam } from "../api";
import { Button, useModalState } from "../components";
import styled from "styled-components";
import { themeColor } from "../common";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../routes";
import clsx from "clsx";
import { BiCreditCard, BiData, BiKey, BiPlusCircle } from "react-icons/bi";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { IoMdAnalytics } from "react-icons/io";
import { TbPlugConnected } from "react-icons/tb";
import LogoImg from "../assets/logo.png";
import { FiUsers } from "react-icons/fi";
import TeamSettings from "./TeamSettings";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${themeColor("text-primary")};

  & h2 {
    text-align: center;
  }

  & label {
    display: block;
    text-align: center;
  }

  & .item {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    color: ${themeColor("link-primary")};

    & svg {
      padding-right: 5px;
      font-size: 1.5rem;
    }

    &:hover {
      background: ${themeColor("background-quaternary")};
    }

    &.active {
      background: ${themeColor("background-secondary")};
    }

    &.disabled,
    &:hover.disabled {
      pointer-events: none;
      color: ${themeColor("text-secondary")};
      background: ${themeColor("background-quaternary")};
      cursor: unset;
    }
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const TeamAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  & > div {
    flex: 1;
  }
  margin: 10px 0;
`;

const AppName = styled.span`
  color: ${themeColor("text-primary")};
  display: block;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: bold;
`;

const TeamName = styled.span`
  color: ${themeColor("text-tertiary")};
  display: block;
  font-size: 0.825rem;
  text-decoration: none;
`;

const Sidebar: React.FC = () => {
  const { isAnonymous, logout } = useAuth();
  const location = useLocation();
  const { selectedApp, selectedTeam } = useTeam();
  const teamsSettings = useModalState();

  return (
    <Container>
      <Link to={paths.dashboard}>
        <h2>
          <Logo src={LogoImg} />
        </h2>
      </Link>
      <hr />
      {isAnonymous && (
        <>
          <Link
            className={clsx("item", {
              active: location.pathname.startsWith(paths.signup),
            })}
            to={paths.signup}
          >
            Join
          </Link>
          <Link
            className={clsx("item", {
              active: location.pathname.startsWith(paths.login),
            })}
            to={paths.login}
          >
            Login
          </Link>
        </>
      )}
      {!isAnonymous && (
        <>
          <TeamAppContainer>
            <div>
              <AppName>{selectedApp?.name}</AppName>
              <TeamName>{selectedTeam?.name}</TeamName>
            </div>
            <Button
              variant="light"
              size="sm"
              onClick={() => teamsSettings.onOpen()}
            >
              <FiSettings size="14" />
            </Button>
          </TeamAppContainer>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.dashboard}
          >
            <AiOutlineDashboard /> Dashboard
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.datasets}
          >
            <BiData /> Datasets
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.endpoints}
          >
            <TbPlugConnected /> Endpoints
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.monitor}
          >
            <IoMdAnalytics /> Monitor
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.apikeys}
          >
            <BiKey /> API Keys
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.configureApp}
          >
            <FiSettings /> Configuration
          </Link>
          <div className="spacer" />
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.billing}
          >
            <BiCreditCard />
            Billing
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.manageTeam}
          >
            <FiUsers />
            Manage Users
          </Link>
          <Link
            className={clsx("item", {
              disabled: !selectedApp,
            })}
            to={paths.createTeam}
          >
            <BiPlusCircle />
            Create a new team
          </Link>
          <Button variant="dark" block flat onClick={() => logout()}>
            Logout
          </Button>
        </>
      )}
      <TeamSettings {...teamsSettings} />
    </Container>
  );
};

export default Sidebar;
