import React from "react";
import PaymentHistory from "./PaymentHistory";
import styled from "styled-components";
import {
  Button,
  ContentCard,
  SimpleModal,
  useModalState,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";
import { useBilling } from "./Provider";

const Container = styled(ContentCard)`
  max-width: 1024px;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & button {
    margin-left: 10px;
  }
`;

const ManagePlan: React.FC = () => {
  const { deletePlan } = useBilling();
  const navigate = useNavigate();
  const deletePlanModal = useModalState();

  return (
    <Container>
      <Toolbar>
        <h3>Billing</h3>
        <div className="spacer" />
        <Button variant="light" onClick={() => navigate(paths.paymentMethod)}>
          Update Payment Method
        </Button>
        <Button variant="light" onClick={() => navigate(paths.selectPlan)}>
          Change Plan
        </Button>
        <Button variant="danger" onClick={() => deletePlanModal.onOpen(null)}>
          Delete
        </Button>
      </Toolbar>
      <PaymentHistory />
      <SimpleModal
        open={deletePlanModal.open}
        onClose={deletePlanModal.onClose}
        title="Are you sure?"
        onDanger={async () => await deletePlan()}
        danger="Delete"
      >
        Are you sure you want to delete your account? This action is not
        reversible.
      </SimpleModal>
    </Container>
  );
};

export default ManagePlan;
