import React, { useEffect } from "react";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { themeColor } from "../common";
import Sidebar from "./Sidebar";
import { useAuth, useTeam } from "../api";
import { paths } from "../routes";

const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: auto;
  grid-template-columns: 300px auto;
  max-height: 100vh;
  overflow-y: hidden;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColor("background-tertiary")};
  color: ${themeColor("text-secondary")};
`;

const Content = styled.main`
  background-color: ${themeColor("background-secondary")};
  color: ${themeColor("text-primary")};
  overflow-y: scroll;
  height: 100vh;
  padding: 24px 32px;
  padding-bottom: 30px;
`;

const Layout: React.FC = () => {
  const { isAnonymous } = useAuth();
  const { selectedTeam, initialised } = useTeam();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initialised && selectedTeam && isAnonymous) {
      navigate(paths.login, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialised, isAnonymous, selectedTeam, location]);

  return (
    <Container>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <Content>
        <Outlet />
      </Content>
      <footer>
        <p>
          {"Copyright © "}{" "}
          <a color="inherit" href="https://apigo.dev">
            apiGO
          </a>{" "}
          {new Date().getFullYear()}
          {"."} All rights reserved.
        </p>
      </footer>
    </Container>
  );
};

export default Layout;
