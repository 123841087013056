import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AppSecurityProvider,
  EndpointsProvider,
  MonitorProvider,
} from "../api";
import { DatasetsProvider } from "../api/datasets";
import { AuthLayout, Layout, MainLayout } from "../layouts";
import {
  ApiKeys,
  ConfigureApp,
  ConfigureDatasetColumns,
  ConfigureEndpoint,
  CreateApiKey,
  CreateApp,
  CreateDataset,
  CreateEndpoint,
  Datasets,
  Endpoints,
  Home,
  Login,
  ManageTeam,
  Monitor,
  Signup,
  TestEndpoint,
  UploadDataset,
  ViewDataset,
  ViewEndpoint,
  Swagger,
  Sales,
  DatasetUploadHistory,
  ConfigureApiKey,
  ConfigureEndpointProvider,
  Billing,
  BillingProvider,
  UpdatePaymentMethod,
  SelectPlan,
} from "../pages";
import { CreateATeam } from "../pages";
import { paths } from "./paths";
import { Marketplace } from "../pages/Marketplace";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={paths.swagger} element={<Swagger />} />
      <Route element={<MainLayout />}>
        <Route index element={<Sales />} />
        <Route path={paths.marketplace} element={<Marketplace />} />
      </Route>
      <Route element={<Layout />}>
        <Route path={paths.login} element={<Login />} />
        <Route path={paths.signup} element={<Signup />} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path={paths.dashboard} element={<Home />} />
        <Route path={paths.createTeam} element={<CreateATeam />} />
        <Route path={paths.manageTeam} element={<ManageTeam />} />
        <Route path={paths.createApp} element={<CreateApp />} />
        <Route path={paths.endpoints} element={<EndpointsProvider />}>
          <Route index element={<Endpoints />} />
          <Route path={paths.viewEndpoint} element={<ViewEndpoint />} />
          <Route path={paths.createEndpoint} element={<CreateEndpoint />} />
          <Route
            path={paths.configureEndpoint}
            element={
              <ConfigureEndpointProvider>
                <ConfigureEndpoint />
              </ConfigureEndpointProvider>
            }
          />
          <Route path={paths.testEndpoint} element={<TestEndpoint />} />
        </Route>
        <Route path={paths.datasets} element={<DatasetsProvider />}>
          <Route index element={<Datasets />} />
          <Route path={paths.createDataset} element={<CreateDataset />} />
          <Route path={paths.viewDataset} element={<ViewDataset />} />
          <Route path={paths.uploadDataset} element={<UploadDataset />} />
          <Route
            path={paths.configureDatasetColumns}
            element={<ConfigureDatasetColumns />}
          />
          <Route
            path={paths.uploadHistory}
            element={<DatasetUploadHistory />}
          />
        </Route>
        <Route path={paths.apikeys} element={<AppSecurityProvider />}>
          <Route index element={<ApiKeys />} />
          <Route path={paths.createApikey} element={<CreateApiKey />} />
          <Route path={paths.updateApikey} element={<ConfigureApiKey />} />
        </Route>
        <Route path={paths.monitor} element={<MonitorProvider />}>
          <Route index element={<Monitor />} />
        </Route>
        <Route path={paths.configureApp}>
          <Route index element={<ConfigureApp />} />
        </Route>
        <Route path={paths.billing} element={<BillingProvider />}>
          <Route index element={<Billing />} />
          <Route path={paths.paymentMethod} element={<UpdatePaymentMethod />} />
          <Route path={paths.selectPlan} element={<SelectPlan />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
