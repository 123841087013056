import React, { createContext, useContext, useEffect, useState } from "react";
import { IStatsSimple } from "@toapi/api";
import { Outlet } from "react-router-dom";
import { useTeam } from "./team";

interface IMonitorState {
  stats: IStatsSimple | null;
}

const defaultMonitorState: IMonitorState = {
  stats: null,
};

const MonitorContext = createContext<IMonitorState>(defaultMonitorState);

export const useMonitor = () => useContext(MonitorContext);

export const MonitorProvider: React.FC = () => {
  const { selectedApp, useAppResource } = useTeam();
  const [, , , { get }] = useAppResource("monitor", selectedApp?.id, false);
  const [stats, setStats] = useState<IStatsSimple | null>(null);

  useEffect(() => {
    if (!selectedApp) return;

    get<IStatsSimple>({
      path: "stats",
    }).then(setStats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp?.id]);

  return (
    <MonitorContext.Provider value={{ stats }}>
      <Outlet />
    </MonitorContext.Provider>
  );
};
