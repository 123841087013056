import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTeam } from "../../api";
import { Badge, Button, Card, Loader, Toolbar } from "../../components";
import { paths } from "../../routes";
import HomeCard from "./Components/HomeCard";
import HomeCardItem from "./Components/HomeCardItem";

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;

  & > div {
    text-align: center;
  }
`;

const MAX_RECORDS_TO_SHOW = 8;

const ViewApp: React.FC = () => {
  const navigate = useNavigate();
  const { selectedApp, summary } = useTeam();

  if (!selectedApp || !summary) return <Loader message="Loading app..." />;

  return (
    <Container>
      <Toolbar>
        <h3>{selectedApp.name}</h3>
        <Badge variant="success" ml="8px">
          Online
        </Badge>
        <div className="spacer" />
        <Button
          variant="success"
          onClick={() => window.open(`/${selectedApp?.id}/swagger`)}
        >
          Swagger Docs
        </Button>
      </Toolbar>
      <Cards>
        <Card>
          <h4>{summary.datasets.length} datasets</h4>
          {summary.datasets.length === 0 && (
            <p>
              <b>
                <Link to={paths.createDataset}>Click here</Link>
              </b>{" "}
              to create a dataset.
            </p>
          )}
        </Card>
        <Card>
          <h4>{summary.records} records</h4>
        </Card>
        <Card>
          <h4>{summary.endpoints.length} endpoints</h4>
          {summary.endpoints.length === 0 && (
            <p>
              <b>
                <Link to={paths.createEndpoint}>Click here</Link>
              </b>{" "}
              to create an endpoint.
            </p>
          )}
        </Card>
      </Cards>
      <Cards>
        <HomeCard
          title="Datasets"
          count={summary.datasets.length ?? 0}
          to="/datasets/create"
        >
          {summary.datasets.slice(0, MAX_RECORDS_TO_SHOW).map((dataset) => (
            <HomeCardItem name={dataset.name} to={`/datasets/${dataset.id}`} />
          ))}
          {summary.datasets.length > MAX_RECORDS_TO_SHOW && (
            <>
              <hr />
              <HomeCardItem name="View all datasets" to="/datasets" />
            </>
          )}
          {summary.datasets.length === 0 && (
            <p>You do not have any datasets.</p>
          )}
        </HomeCard>
        <HomeCard
          title="Endpoints"
          count={summary.endpoints.length ?? 0}
          to="/endpoints/create"
        >
          {summary.endpoints.slice(0, MAX_RECORDS_TO_SHOW).map((endpoint) => (
            <HomeCardItem
              name={endpoint.name}
              to={`/endpoints/${endpoint.id}`}
            />
          ))}
          {summary.endpoints.length > MAX_RECORDS_TO_SHOW && (
            <>
              <hr />
              <HomeCardItem name="View all endpoints" to="/endpoints" />
            </>
          )}
          {summary.endpoints.length === 0 && (
            <p>You do not have any endpoints.</p>
          )}
        </HomeCard>
        <HomeCard
          title="API Keys"
          count={summary.apiKeys.length ?? 0}
          to="/apikeys/create"
        >
          {summary.apiKeys.slice(0, MAX_RECORDS_TO_SHOW).map((apikey) => (
            <HomeCardItem name={apikey.name} to="/apikeys" />
          ))}
          {summary.apiKeys.length > MAX_RECORDS_TO_SHOW && (
            <>
              <hr />
              <HomeCardItem name="View all API Keys" to="/apikeys" />
            </>
          )}
          {summary.apiKeys.length === 0 && <p>You do not have any API Keys.</p>}
        </HomeCard>
      </Cards>
    </Container>
  );
};

export default ViewApp;
