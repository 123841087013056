import { useFormik } from "formik";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSecurity } from "../../api";
import * as yup from "yup";
import { paths } from "../../routes";
import {
  Button,
  CheckboxInput,
  ContentCard,
  TextInput,
} from "../../components";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
  description: yup.string(),
  isActive: yup.boolean(),
});

const ConfigureApiKey: React.FC = () => {
  const navigate = useNavigate();
  const { updateApiKey, apikeys } = useAppSecurity();
  const { id } = useParams();

  const apiKey = useMemo(() => apikeys.find((c) => c.id === id), [id, apikeys]);

  const formik = useFormik({
    initialValues: {
      name: apiKey?.name ?? "",
      description: apiKey?.description ?? "",
      isActive: apiKey?.isActive ?? false,
    },
    validationSchema,
    onSubmit: async (request, { setSubmitting, setFieldError }) => {
      try {
        if (!id) return;

        setSubmitting(true);
        await updateApiKey(id, request);
        navigate(paths.apikeys);
        setSubmitting(false);
      } catch (e: any) {
        setFieldError("name", e.message);
      }
    },
  });

  return (
    <ContentCard>
      <h3>Update api key</h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <CheckboxInput
          name="isActive"
          label="is active?"
          checked={formik.values.isActive}
          onChange={formik.handleChange}
        />
        <TextInput
          name="name"
          label="Api Key name"
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <TextInput
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          touched={formik.touched.description}
          error={formik.errors.description}
        />

        <hr />
        <Button variant="success">Update API Key</Button>
      </form>
    </ContentCard>
  );
};

export default ConfigureApiKey;
