import React from "react";
import { useTeam } from "../../api";
import SelectPlan from "./SelectPlan";
import ManagePlan from "./ManagePlan";

const Billing: React.FC = () => {
  const { selectedTeam } = useTeam();
  return !selectedTeam?.stripeCustomerId ? <SelectPlan /> : <ManagePlan />;
};

export default Billing;
