import React from "react";
import {
  Button,
  CheckboxInput,
  ContentCard,
  SelectInput,
  TextInput,
} from "../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDatasets } from "../../api";
import { datasetType } from "@toapi/api";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
  type: yup.mixed().oneOf(["csv", "json", "excel"]),
  defaultEndpoints: yup.boolean().default(true),
});

const CreateDataset: React.FC = () => {
  const navigate = useNavigate();
  const { createDataset } = useDatasets();

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "csv",
      defaultEndpoints: true,
    },
    validationSchema,
    onSubmit: async (
      { name, type, defaultEndpoints },
      { setSubmitting, setFieldError }
    ) => {
      setSubmitting(true);
      try {
        const id = await createDataset(name, type as datasetType, {
          getById: defaultEndpoints,
          getAll: defaultEndpoints,
          create: false,
          update: false,
          delete: false,
        });
        navigate(`/datasets/${id}/upload`);
      } catch (e) {
        console.error(e);
        // TODO handle error
        setFieldError("name", "An error has occured. Try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <ContentCard>
      <h3>Create a dataset</h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="Dataset name"
          placeholder=""
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <SelectInput
          name="type"
          label="Dataset type"
          value={formik.values.type}
          onChange={formik.handleChange}
        >
          <option value="csv">CSV</option>
          <option value="json" disabled>
            JSON (coming soon)
          </option>
          <option value="excel">Excel</option>
        </SelectInput>
        <hr />
        <CheckboxInput
          name="defaultEndpoints"
          label="Generate default endpoints"
          checked={formik.values.defaultEndpoints}
          onChange={formik.handleChange}
        />
        <hr />
        <Button variant="success">Create dataset</Button>
      </form>
    </ContentCard>
  );
};

export default CreateDataset;
