import React, { useMemo } from "react";
import { useTable } from "react-table";
import { useDatasets } from "../../api";
import { Table } from "../../components";

const DatasetTable: React.FC = () => {
  const { dataset, datasetData } = useDatasets();

  const data = useMemo(() => datasetData, [datasetData]);

  const renderCell = (value: any) => {
    switch (typeof value) {
      case "boolean":
        return <input type="checkbox" readOnly checked={value} />;
      default:
        return value;
    }
  };

  const columns: any[] = useMemo(
    () =>
      dataset?.metadata?.columns?.map((column) => ({
        Header: column.name,
        accessor: column.originalName.trim().replaceAll(" ", "_"),
        Cell: ({ value }: any) => renderCell(value),
      })) ?? [],
    [dataset]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DatasetTable;
