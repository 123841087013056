import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { themeColor } from "../common";

interface TabProps {
  tabKey: string;
  title: string;
  disabled?: boolean;
}

const TabContainer = styled.div<TabProps>``;

export const Tab: React.FC<PropsWithChildren<TabProps>> = ({
  children,
  disabled,
  ...props
}) => {
  return <TabContainer {...props}>{children}</TabContainer>;
};

interface TabsProps {
  activeTabKey: string;
  setTab(key: string): void;
}

const TabsContainer = styled.div`
  & > .tabs-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${themeColor("border-primary")};

    & > span {
      cursor: pointer;
      text-align: center;
      padding: 10px 20px;
      border-bottom: 4px solid transparent;
      font-weight: 500;
      color: ${themeColor("text-tertiary")};

      &.active {
        color: ${themeColor("text-primary")};
        border-bottom: 4px solid ${themeColor("success")};
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  & .content {
    margin: 10px 0;
  }
`;

export const Tabs: React.FC<PropsWithChildren<TabsProps>> = ({
  activeTabKey,
  setTab,
  children,
}) => {
  const arrayChildren = React.Children.toArray(children);

  return (
    <TabsContainer>
      <>
        <div className="tabs-nav">
          {React.Children.map(children, (child: any) => (
            <span
              className={clsx({
                active: child.props.tabKey === activeTabKey,
                disabled: child.props.disabled,
              })}
              onClick={() =>
                !child.props.disabled && setTab(child.props.tabKey)
              }
            >
              {child.props.title}
            </span>
          ))}
        </div>
        <div className="content">
          {arrayChildren.filter((c: any) => c.props.tabKey === activeTabKey)}
        </div>
      </>
    </TabsContainer>
  );
};
