"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryOperator = exports.queryParamType = exports.getMethodRetrievalType = exports.httpMethod = void 0;
var httpMethod;
(function (httpMethod) {
    httpMethod["get"] = "get";
    httpMethod["post"] = "post";
    httpMethod["patch"] = "patch";
    httpMethod["put"] = "put";
    httpMethod["delete"] = "delete";
})(httpMethod = exports.httpMethod || (exports.httpMethod = {}));
var getMethodRetrievalType;
(function (getMethodRetrievalType) {
    getMethodRetrievalType["item"] = "item";
    getMethodRetrievalType["collection"] = "collection";
})(getMethodRetrievalType = exports.getMethodRetrievalType || (exports.getMethodRetrievalType = {}));
var queryParamType;
(function (queryParamType) {
    queryParamType["alphanum"] = "alphanum";
    queryParamType["pattern"] = "pattern";
    queryParamType["string"] = "string";
    queryParamType["number"] = "number";
    queryParamType["email"] = "email";
    queryParamType["date"] = "date";
    queryParamType["boolean"] = "boolean";
})(queryParamType = exports.queryParamType || (exports.queryParamType = {}));
var queryOperator;
(function (queryOperator) {
    queryOperator["<"] = "<";
    queryOperator["<="] = "<=";
    queryOperator["=="] = "==";
    queryOperator[">"] = ">";
    queryOperator[">="] = ">=";
    queryOperator["!="] = "!=";
    queryOperator["array-contains"] = "array-contains";
    queryOperator["array-contains-any"] = "array-contains-any";
    queryOperator["search"] = "search";
})(queryOperator = exports.queryOperator || (exports.queryOperator = {}));
