import {
  addDays,
  addMinutes,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useMonitor } from "../../api";
import { Card } from "../../components";
import { nextIntervalDate } from "./date-utils";

const labels = {
  totalRequests: "Total Requests",
  totalFailures: "Total Failures",
};

const RequestsLineChart: React.FC = () => {
  const { stats } = useMonitor();

  const data = useMemo(() => {
    const result = [];

    const today = nextIntervalDate(15);
    console.log(today);

    let som = addDays(today, -1);

    do {
      result.push({
        date: format(som, "p"),
        totalRequests: stats?.t24h.requests[som.toString()] ?? 0,
        totalFailures: stats?.t24h.failures[som.toString()] ?? 0,
      });

      som = addMinutes(som, 15);
    } while (som <= today);

    return result;
  }, [stats]);

  return (
    <Card>
      <h4>
        Requests <small>(Last 24 hours)</small>
      </h4>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" interval={15} />
          <YAxis />
          <Tooltip />
          <Legend
            formatter={(value: "totalFailures" | "totalRequests") =>
              labels[value]
            }
          />
          <Line type="monotone" dataKey="totalFailures" stroke="#FF461F" />
          <Line
            type="monotone"
            dataKey="totalRequests"
            stroke="#0082e3"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default RequestsLineChart;
