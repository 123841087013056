import React, { useMemo } from "react";
import {
  FiInfo,
  FiPlusCircle,
  FiSettings,
  FiShare2,
  FiTrash2,
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEndpoints } from "../../api";
import { Badge, Button, Card, Loader, NoData, Table } from "../../components";
import { paths } from "../../routes";
import { httpMethod, IEndpointSimple } from "@toapi/api";

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & button {
    margin-left: 10px;
  }
`;

const Endpoints: React.FC = () => {
  const navigate = useNavigate();
  const { endpoints, loadingEndpoints } = useEndpoints();

  const getMethodColor = (method: httpMethod) => {
    switch (method) {
      case httpMethod.get:
        return "read";
      case httpMethod.post:
        return "success";
      case httpMethod.patch:
      case httpMethod.put:
        return "warning";
      case httpMethod.delete:
        return "danger";
      default:
        return "danger";
    }
  };

  const groupedEndpoints = useMemo(
    () =>
      endpoints.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.tag]: [...(acc[cur.tag] ?? []), cur],
        }),
        {} as Record<string, IEndpointSimple[]>
      ),
    [endpoints]
  );

  if (loadingEndpoints) return <Loader message="Loading endpoints..." />;

  return (
    <div>
      <Toolbar>
        <h3>Endpoints</h3>
        <div className="spacer" />
        {/* <TextInput name="search" placeholder="Search" inline /> */}
        <Button
          variant="success"
          onClick={() => navigate(paths.createEndpoint)}
        >
          <FiPlusCircle /> Add Endpoint
        </Button>
      </Toolbar>
      {endpoints.length === 0 && (
        <Card>
          <NoData
            title="No endpoints found"
            subtitle="Add a new endpoint to get started."
          />
        </Card>
      )}
      {Object.entries(groupedEndpoints).map(([key, values]) => (
        <Card>
          <h3>{key}</h3>
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Endpoint</th>
                  <th>Type</th>
                  <th>Source</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.map((endpoint) => (
                  <tr
                    key={endpoint.id}
                    onClick={() => navigate(`/endpoints/${endpoint.id}`)}
                  >
                    <td>
                      <Link to="">
                        <Badge variant={getMethodColor(endpoint.method)}>
                          {endpoint.method.toLocaleUpperCase()}
                        </Badge>{" "}
                        {endpoint.path}
                      </Link>
                    </td>
                    <td>
                      <FiShare2 />
                      API - REST <FiInfo />
                    </td>
                    <td>CSV: Tickers</td>
                    <td>
                      <Badge variant="success">Enabled</Badge>
                    </td>
                    <td>
                      <Link
                        to={`/endpoints/${endpoint.id}/configure`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <FiSettings />
                      </Link>
                    </td>
                    <td>
                      <FiTrash2 />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default Endpoints;
