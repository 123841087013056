import styled from "styled-components";

interface AlertProps {
  variant: "success" | "read" | "info" | "warning" | "danger";
}

export const Alert = styled.div<AlertProps>`
  display: flex;
  align-items: center;
  background: ${(props) => `${props.theme.colors[props.variant]}33`};
  border: 0.5px solid ${(props) => `${props.theme.colors[props.variant]}44`};
  color: ${(props) => props.theme.colors[props.variant]};
  padding: 15px 10px;
  border-radius: 5px;
  margin: 10px 0;
  font-weight: 600;
  font-size: 0.925rem;

  & :not(:first-child) {
    margin-left: 5px;
  }
`;
