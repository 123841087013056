import React from "react";
import Benefits from "./Benefits";
import FinalCallToAction from "./FinalCallToAction";
import HowDoesItWork from "./HowDoesItWork";
import Introduction from "./Introduction";
import OurFeatures from "./OurFeatures";
import { PaddedContainer } from "./PaddedContainer";
import Pricing from "./Pricing";

const Sales: React.FC = () => {
  return (
    <div>
      <Introduction />
      <HowDoesItWork />
      <PaddedContainer>
        <OurFeatures />
      </PaddedContainer>
      <Pricing />
      <Benefits />
      <FinalCallToAction />
    </div>
  );
};

export default Sales;
