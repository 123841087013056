import styled from "styled-components";
import { themeColor, ThemeProps } from "../common";

type ButtonVariant =
  | "success"
  | "info"
  | "read"
  | "danger"
  | "pending"
  | "warning"
  | "dark"
  | "light"
  | "transparent";

interface ButtonProps {
  variant: ButtonVariant;
  block?: boolean;
  flat?: boolean;
  size?: "sm" | "md" | "lg";
}

const buttonVariant =
  (variant: ButtonVariant) =>
  ({ theme }: ThemeProps) => {
    switch (variant) {
      case "success":
        return `background-color: ${theme.colors.success}`;
      case "info":
        return `background-color: ${theme.colors.info}`;
      case "read":
        return `background-color: ${theme.colors.read}`;
      case "danger":
        return `background-color: ${theme.colors.danger}`;
      case "pending":
        return `background-color: ${theme.colors.pending}`;
      case "warning":
        return `background-color: ${theme.colors.warning}`;
      case "dark":
        return `background-color: ${theme.colors["background-primary"]}`;
      case "light":
        return `background-color: ${theme.colors["background-secondary"]}; color: ${theme.colors["text-primary"]}`;
      case "transparent":
        return `background-color: transparent; color: ${theme.colors["text-primary"]}`;
    }
  };

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
  ${(props) => buttonVariant(props.variant)};
  ${(props) => (props.block ? "width: 100%;" : "")}
  ${(props) => (props.block ? "border-radius: unset;" : "")}

  ${(props) => (props.size === "sm" ? "font-size: 0.625rem;" : "")}
  ${(props) => (props.size === "sm" ? "padding: 5px 10px;" : "")}
  ${(props) => (props.size === "md" ? "font-size: 0.925rem;" : "")}
  ${(props) => (props.size === "md" ? "padding: 8px 12px;" : "")}
  ${(props) => (props.size === "lg" ? "font-size: 1.125rem;" : "")}
  ${(props) => (props.size === "lg" ? "padding: 10px 15px;" : "")}

  &:hover {
    opacity: 0.8;
  }

  & > svg {
    margin: 0 5px;
  }

  &:disabled {
    background: ${themeColor("background-secondary")};
    cursor: unset;
  }
`;
