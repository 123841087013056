import React from "react";
import {
  FiPlusCircle,
  FiRefreshCw,
  FiSettings,
  FiTrash2,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAppSecurity } from "../../api";
import { Button, Card, Table, Toolbar } from "../../components";
import { paths } from "../../routes";
import Token from "./Token";

const ApiKeys: React.FC = () => {
  const navigate = useNavigate();
  const { apikeys, refresh } = useAppSecurity();

  return (
    <div>
      <Toolbar>
        <h3>Api Keys</h3>
        <div className="spacer" />
        <Button variant="success" onClick={() => navigate(paths.createApikey)}>
          <FiPlusCircle /> Add API Key
        </Button>
        <Button variant="read" onClick={() => refresh()}>
          <FiRefreshCw />
        </Button>
      </Toolbar>
      <Card>
        <Table>
          <thead>
            <tr>
              <th>API Key</th>
              <th>Description</th>
              <th>Token</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {apikeys.map((apikey) => (
              <tr key={apikey.id}>
                <td>{apikey.name}</td>
                <td>{apikey.description}</td>
                <td>
                  <Token id={apikey.id} />
                </td>
                <td>
                  <Button
                    variant="transparent"
                    onClick={() => navigate(`${apikey.id}/update`)}
                  >
                    <FiSettings />
                  </Button>
                </td>
                <td>
                  <FiTrash2 />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default ApiKeys;
