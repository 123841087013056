import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface Props {
  data: any[];
  getLabel(value: string): string;
  dataKey: string;
  stroke?: string;
}

const Chart60Mins: React.FC<Props> = ({
  data,
  getLabel,
  dataKey,
  stroke = "#0082e3",
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" interval={20} />
        <YAxis />
        <Tooltip />
        <Legend formatter={getLabel} />
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke={stroke}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart60Mins;
