import React, { useEffect, useState } from "react";
import { FiXCircle } from "react-icons/fi";
import styled from "styled-components";
import { useEndpoints } from "../../../api";
import { shortId } from "../../../common";
import {
  Button,
  ErrorMessage,
  SelectInput,
  TextInput,
} from "../../../components";
import { httpMethod, IQueryFilter, queryOperator } from "@toapi/api";
import { useConfigureEndpoint } from "./Provider";

const FilterContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr 3fr 1fr;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;

  & input,
  option,
  select {
    font-size: 0.725rem;
  }
`;

const Head = styled.span`
  font-weight: bold;
  font-size: 0.825rem;
`;

const FilterTab: React.FC = () => {
  const { setIsDirty, reset } = useConfigureEndpoint();
  const { endpoint, updateEndpoint } = useEndpoints();
  const [filters, setFilters] = useState<IQueryFilter[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (endpoint?.filters) {
      setFilters([...(endpoint.filters ?? [])]);
    }
  }, [endpoint?.filters]);

  const onQueryParamUpdate = (filterId: string, param: string, value: any) => {
    setIsDirty();

    setFilters(
      filters.map((c) =>
        c.id === filterId
          ? {
              ...c,
              [param]: value,
            }
          : c
      )
    );
    setError("");
  };

  if (!endpoint) return null;

  const onAddFilter = () => {
    setIsDirty();

    setFilters([
      ...filters,
      {
        id: shortId(),
        columnId: "",
        operator: queryOperator["=="],
        value: "",
        valueType: "query",
      },
    ]);
  };
  const onSave = async () => {
    if (filters.some((c) => c.columnId === "" || c.value === "")) {
      setError("All filters must be set");
      return;
    }

    reset();
    await updateEndpoint(endpoint.id, { filters });
  };

  return (
    <div>
      <h4>Query filters</h4>
      <hr />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FilterContainer>
        <Head>Mapping</Head>
        <Head>Operator</Head>
        <Head>FilterType</Head>
        <Head>Query</Head>
        <Head></Head>
        {filters.map((filter) => (
          <>
            <span>
              <SelectInput
                name="columnId"
                value={filter.columnId}
                onChange={(e) =>
                  onQueryParamUpdate(filter.id, "columnId", e.target.value)
                }
              >
                <option />
                {endpoint.datasetMetadata.columns?.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </SelectInput>
            </span>
            <span>
              <SelectInput
                name="operator"
                value={filter.operator}
                onChange={(e) =>
                  onQueryParamUpdate(filter.id, "operator", e.target.value)
                }
              >
                <option />
                {Object.values(queryOperator).map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </SelectInput>
            </span>
            <span>
              <SelectInput
                name="valueType"
                value={filter.valueType}
                onChange={(e) =>
                  onQueryParamUpdate(filter.id, "valueType", e.target.value)
                }
              >
                <option />
                {["value", "query"].map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </SelectInput>
            </span>
            <span>
              {filter.valueType === "query" && (
                <SelectInput
                  name="value"
                  value={filter.value}
                  onChange={(e) =>
                    onQueryParamUpdate(filter.id, "value", e.target.value)
                  }
                >
                  <option />
                  {endpoint.query?.map((param) => (
                    <option key={param.name} value={param.name}>
                      {param.name}
                    </option>
                  ))}
                </SelectInput>
              )}
              {filter.valueType === "value" && (
                <TextInput
                  name="value"
                  value={filter.value}
                  onChange={(e) =>
                    onQueryParamUpdate(filter.id, "value", e.target.value)
                  }
                />
              )}
            </span>
            <span>
              <FiXCircle
                onClick={() =>
                  setFilters(filters.filter((c) => c.id !== filter.id))
                }
              />
            </span>
          </>
        ))}
      </FilterContainer>
      <Button
        variant="read"
        onClick={onAddFilter}
        disabled={endpoint.method !== httpMethod.get}
      >
        Add filter
      </Button>
      <hr />
      <Button variant="success" onClick={onSave}>
        Save
      </Button>
    </div>
  );
};

export default FilterTab;
