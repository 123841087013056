import styled from "styled-components";
import { themeColor } from "../common";

export const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: ${themeColor("success")};
    color: white;
  }

  & a {
    text-decoration: none;
    color: ${themeColor("link-primary")};
  }

  tr:nth-child(even) {
    background-color: ${themeColor("background-quaternary")};
  }

  td,
  th {
    padding: 16px;
  }

  tr:hover {
    cursor: pointer;
    background-color: ${themeColor("background-secondary")};
  }
`;
