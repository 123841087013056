import { ICreateSubscription, ISubscription } from "@toapi/api";
import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { useResource } from "../../common";
import { useTeam } from "../../api";
import { IInvoice } from "@toapi/api/src/subscription";

interface IBillingState {
  invoices: IInvoice[];
  selectPlan(request: ICreateSubscription): Promise<void>;
  updatePaymentMethod(paymentMethodId: string): Promise<boolean>;
  deletePlan(): Promise<void>;
}

const defaultState: IBillingState = {
  invoices: [],
  selectPlan: () => Promise.resolve(),
  updatePaymentMethod: () => Promise.resolve(false),
  deletePlan: () => Promise.resolve(),
};

const BillingContext = createContext(defaultState);

export const useBilling = () => useContext(BillingContext);

export const BillingProvider: React.FC = () => {
  const { selectedTeam, reloadTeam } = useTeam();

  const [subscriptions, isSubscriptionsLoading, , subscriptionsApi] =
    useResource<ISubscription>(
      selectedTeam ? `teams/${selectedTeam.id}/subscriptions` : undefined
    );

  const [invoices] = useResource<IInvoice>(
    selectedTeam ? `teams/${selectedTeam.id}/subscriptions/invoices` : undefined
  );

  const selectPlan = async (data: ICreateSubscription) => {
    if (selectedTeam?.stripeCustomerId) {
      const id = await subscriptionsApi.put<string>({
        body: data,
      });
    } else {
      const id = await subscriptionsApi.post<string>({
        body: data,
      });
    }
    await reloadTeam();
  };

  const updatePaymentMethod = async (paymentMethodId: string) => {
    const result = await subscriptionsApi.put<boolean>({
      path: "payment",
      body: { paymentMethodId },
    });

    return result ?? false;
  };

  const deletePlan = async () => {
    await subscriptionsApi.delete<boolean>({});
  };

  return (
    <BillingContext.Provider
      value={{
        invoices: invoices.sort(
          (a, b) =>
            new Date(b.created).getDate() - new Date(a.created).getDate()
        ),
        selectPlan,
        updatePaymentMethod,
        deletePlan,
      }}
    >
      <Outlet />
    </BillingContext.Provider>
  );
};
