import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from "react";
import { io } from "socket.io-client";
import env from "../env";
import { useAuth } from "./auth";
import { useToast } from "./toast";

export enum eventsToSubscribe {
  onDatasetFileUploaded = "onDatasetFileUploaded",
}

interface ISocketSubscriberState {
  subscribe(event: eventsToSubscribe, callback: Function): Function;
}

const socketSubscriberState: ISocketSubscriberState = {
  subscribe: () => () => {},
};

const SocketSubscriberContext = createContext(socketSubscriberState);

export const useSocketSubscriber = () => useContext(SocketSubscriberContext);

export const SocketSubscriber: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { notify } = useToast();
  const eventsSubscribed = useRef<Record<eventsToSubscribe, Function[]>>(
    {} as any
  );
  let initialised = useRef(false);

  const notifySubscriber = (event: eventsToSubscribe, message: any) => {
    if (!eventsSubscribed.current) return;

    for (const subscriber of eventsSubscribed.current[event]) {
      subscriber(message);
    }
  };

  useEffect(() => {
    if (!user) return;
    if (initialised.current) return;

    const socket = io(env.signal_r);
    initialised.current = true;

    // TODO JWT
    socket.on("connect", () => {
      console.log("connected");
      socket.emit("join", { socketId: socket.id, userId: user._id });
    });
    socket.on("connect_error", (e) => {
      console.log("error", e);
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on("disconnect", () => {
      console.log("disconnected");
      initialised.current = false;
    });

    socket.on(user._id, (message) => {
      console.log("message", message);
      switch (message.type) {
        case eventsToSubscribe.onDatasetFileUploaded: {
          notify(message.message, "success");
          notifySubscriber(message.type, message);
          break;
        }
      }
    });

    return () => {
      initialised.current = false;
      socket.off("connect");
      socket.off("disconnect");
      socket.off(user._id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const unsubscribe = (event: eventsToSubscribe, callback: Function) => {
    if (eventsSubscribed.current) {
      eventsSubscribed.current[event] = eventsSubscribed.current[event].filter(
        (c) => c !== callback
      );
    }
  };

  const subscribe = (event: eventsToSubscribe, callback: Function) => {
    if (eventsSubscribed.current) {
      eventsSubscribed.current[event] = [
        ...(eventsSubscribed.current[event] ?? []),
        callback,
      ];
    }

    return () => unsubscribe(event, callback);
  };

  return (
    <SocketSubscriberContext.Provider value={{ subscribe }}>
      {children}
    </SocketSubscriberContext.Provider>
  );
};
