import { PaymentMethod } from "@stripe/stripe-js";
import { ICreatePaymentMethod } from "@toapi/api";
import React, { useState } from "react";
import { Button, ErrorMessage } from "../../components";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { colors } from "../../common";

const stripeElementsOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },

  hidePostalCode: true,
};

interface Props {
  onCardCollected(
    data: PaymentMethod & Partial<ICreatePaymentMethod>
  ): Promise<void>;
  button?: string | undefined;
  buttonSubmitting?: string | undefined;
}

const PaymentMethodCardCollector: React.FC<Props> = ({
  onCardCollected,
  button = "Subscribe",
  buttonSubmitting = "Subscribing...",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const onPaymentMethod = async () => {
    if (!submitting) {
      if (stripe && elements) {
        setSubmitting(true);
        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
          });

          if (error) {
            setError(error.message ?? "");
          } else {
            onCardCollected({
              ...paymentMethod,
              cardBrand: paymentMethod.card?.brand ?? "",
              cardExpMonth: paymentMethod.card?.exp_month.toString() ?? "",
              cardLast4: paymentMethod.card?.last4 ?? "",
            });
          }
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <div>{error && <ErrorMessage>{error}</ErrorMessage>}</div>
      <div
        style={{
          position: "relative",
          minHeight: "38px",
          padding: "15px",
          margin: "20px 0",
          backgroundColor: colors["background-quaternary"],
        }}
      >
        <CardElement options={stripeElementsOptions} />
        <fieldset
          style={{
            borderColor: colors["border-primary"],
            // color: colors["text-primary"],
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "4px",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "0",
            padding: "0 8px",
            overflow: "hidden",
            pointerEvents: "none",
          }}
        ></fieldset>
      </div>
      <Button
        variant="success"
        block
        size="lg"
        onClick={onPaymentMethod}
        disabled={submitting}
      >
        {submitting ? buttonSubmitting : button}
      </Button>
    </>
  );
};

export default PaymentMethodCardCollector;
