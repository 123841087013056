"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activityAction = exports.activitySourceType = void 0;
var activitySourceType;
(function (activitySourceType) {
    activitySourceType["team"] = "team";
    activitySourceType["app"] = "app";
    activitySourceType["dataset"] = "dataset";
    activitySourceType["endpoint"] = "endpoint";
    activitySourceType["apikeys"] = "apikeys";
})(activitySourceType = exports.activitySourceType || (exports.activitySourceType = {}));
var activityAction;
(function (activityAction) {
    activityAction["create"] = "create";
    activityAction["update"] = "update";
})(activityAction = exports.activityAction || (exports.activityAction = {}));
