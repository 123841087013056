import styled from "styled-components";
import { boxShadow, themeColor } from "../common";

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${themeColor("background-tertiary")};
  ${boxShadow()};
  font-weight: 500;
  color: ${themeColor("text-primary")};
  margin: 10px 0;

  & a {
    text-decoration: none;
    color: ${themeColor("link-primary")};
  }
`;
