import React from "react";
import styled from "styled-components";
import { useMonitor } from "../../api";
import { Card, Toolbar } from "../../components";
import FailuresChart60Mins from "./FailuresChart60Mins";
import RequestsLineChart from "./RequestsLineChart";
import RequestChart60Mins from "./RrquestChart60Mins";

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;

  & > div {
    text-align: center;
    & > h4 {
      margin: 0;
    }
    & > h6 {
      margin-top: 5px;
      font-size: 0.725rem;
      font-weight: 100;
    }
  }
`;

const RequestsCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  & > div {
    text-align: center;
    & > h4 {
      margin: 0;
    }
    & > h6 {
      margin-top: 5px;
      font-size: 0.725rem;
      font-weight: 100;
    }
  }
`;

const sumOfRequests = (requests: Record<string, number>) =>
  Object.values(requests).reduce((acc, cur) => acc + cur, 0);

const Monitor: React.FC = () => {
  const { stats } = useMonitor();

  if (!stats) return null;

  return (
    <Container>
      <Toolbar>
        <h3>Monitoring</h3>
      </Toolbar>
      <Cards>
        <Card>
          <h4>Requests</h4>
          <h6>(Last 24 hours)</h6>
          <h1>{sumOfRequests(stats.t24h.requests)}</h1>
        </Card>
        <Card>
          <h4>Failed Requests</h4>
          <h6>(Last 24 hours)</h6>
          <h1>{sumOfRequests(stats.t24h.failures)}</h1>
        </Card>
        <Card>
          <h4>Average Response Time</h4>
          <h6>(Last 24 hours)</h6>
          <h1>{Math.round(stats.t24h.averageResponseTime * 100) / 100} ms</h1>
        </Card>
      </Cards>
      <RequestsCards>
        <Card>
          <h4>Requests</h4>
          <h6>(Last 60 minutes)</h6>
          <RequestChart60Mins />
        </Card>
        <Card>
          <h4>Failed Requests</h4>
          <h6>(Last 60 minutes)</h6>
          <FailuresChart60Mins />
        </Card>
      </RequestsCards>
      <RequestsLineChart />
    </Container>
  );
};

export default Monitor;
