import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../api";
import { paths } from "../routes";
import Layout from "./Layout";

const AuthLayout: React.FC = () => {
  const { isAnonymous } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAnonymous) {
      navigate(paths.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymous]);

  return <Layout />;
};

export default AuthLayout;
