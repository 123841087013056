import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDatasets } from "../../api";
import { ContentCard, Loader } from "../../components";
import DatasetTable from "./DatasetTable";

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 25px;

  & a {
    display: block;
    margin: 5px 0;
  }

  & > div {
    width: 100%;
    max-width: 100%;
  }
`;

const ViewDataset: React.FC = () => {
  const { dataset, loadingDataset } = useDatasets();

  if (loadingDataset) return <Loader message="Loading dataset..." />;
  if (!dataset) return <div>Dataset not found...</div>; // TODO handle 404

  return (
    <Container>
      <ContentCard>
        <h3>Dataset: {dataset.name}</h3>
        <hr />
        <DatasetTable />
      </ContentCard>
      <ContentCard>
        <h3>Settings</h3>
        <hr />
        <Link to="upload">New upload</Link>
        <Link to="history">View upload history</Link>
        <Link to="columns">Manage columns</Link>
        {/* <Link to="">Caching rules</Link> */}
      </ContentCard>
    </Container>
  );
};

export default ViewDataset;
