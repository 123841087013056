import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuth, useTeam } from "../../api";
import { Button, TextInput } from "../../components";
import { paths } from "../../routes";
import { Container, Content, Heading } from "./Form";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const Signup: React.FC = () => {
  const { isAnonymous, signUpWithPassword } = useAuth();
  const { setTeam } = useTeam();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({ email, password }, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        const result = await signUpWithPassword(email, password);
        console.log("sign up");

        if (result && result.team) {
          console.log("setting team");
          setTeam(result.team);
        }
        navigate(paths.createApp, { replace: true });
      } catch (e) {
        setFieldError("email", "An error has occured. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isAnonymous) {
      navigate(paths.dashboard, { replace: true });
    }
  }, [isAnonymous]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <Heading>
            <Link to={paths.login}>Sign in</Link> |{" "}
            <Link to={paths.signup} className="active">
              Join
            </Link>
          </Heading>
          <hr />
          <TextInput
            label="Email"
            name="email"
            placeholder="Your email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            touched={formik.touched.email}
            error={formik.errors.email}
          />
          <TextInput
            label="Password"
            name="password"
            placeholder="Your password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            touched={formik.touched.password}
            error={formik.errors.password}
          />
          <Button variant="success">Join with Email</Button>
          <div className="terms">
            By signing up, I agree to <b>apiGO's</b>{" "}
            <Link to="">Terms of Service</Link> and{" "}
            <Link to="">Privacy Policy</Link>
          </div>
        </Content>
      </form>
    </Container>
  );
};

export default Signup;
