import React, { useEffect } from "react";
import { Button, ContentCard, TextArea, TextInput } from "../../components";

import * as yup from "yup";
import { useFormik } from "formik";
import { useTeam, useToast } from "../../api";

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
});

const ConfigureApp: React.FC = () => {
  const { selectedApp, updateApp } = useTeam();
  const { notify } = useToast();

  const formik = useFormik({
    initialValues: {
      name: selectedApp?.name ?? "",
      description: selectedApp?.description ?? "",
      developer: selectedApp?.developer ?? "",
      website: selectedApp?.website ?? "",
      docs: selectedApp?.docs ?? "",
    },
    validationSchema,
    onSubmit: async (request, { setSubmitting, setFieldError }) => {
      try {
        setSubmitting(true);
        await updateApp(
          request.name,
          request.description,
          request.developer,
          request.website,
          request.docs
        );
        formik.resetForm();
        notify("App details updated successfully.", "success");
        setSubmitting(false);
      } catch (e: any) {
        setFieldError("name", e.message);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      name: selectedApp?.name ?? "",
      description: selectedApp?.description ?? "",
      developer: selectedApp?.developer ?? "",
      website: selectedApp?.website ?? "",
      docs: selectedApp?.docs ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  return (
    <ContentCard>
      <h4>Update app</h4>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="App name"
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <TextArea
          name="description"
          label="App Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          touched={formik.touched.description}
          error={formik.errors.description}
        />
        <TextInput
          name="developer"
          label="Developer/Team name"
          value={formik.values.developer}
          onChange={formik.handleChange}
          touched={formik.touched.developer}
          error={formik.errors.developer}
        />
        <TextInput
          name="website"
          label="Contact website"
          value={formik.values.website}
          onChange={formik.handleChange}
          touched={formik.touched.website}
          error={formik.errors.website}
        />
        <TextInput
          name="docs"
          label="Documentation and main site"
          value={formik.values.docs}
          onChange={formik.handleChange}
          touched={formik.touched.docs}
          error={formik.errors.docs}
        />
        <hr />
        <Button variant="success">Update App</Button>
      </form>
    </ContentCard>
  );
};

export default ConfigureApp;
