import React, { useState } from "react";
import styled from "styled-components";
import { mobileMediaQuery, themeColor } from "../../common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  ${mobileMediaQuery} {
    padding-right: 10px;
  }

  .note,
  h4,
  h6 {
    text-align: center;

    ${mobileMediaQuery} {
      padding: 0 10px;
    }
  }

  .note {
    color: ${themeColor("read")};
    font-size: 0.925rem;
    padding-bottom: 10px;
    font-weight: 400;
    border-bottom: 1px solid ${themeColor("read")};
  }

  h4 {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 10px 0;
  }

  h6 {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 10px 0;
    margin-bottom: 20px;
    color: ${themeColor("text-primary")};
  }
`;

const FeaturesContainer = styled.div`
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
  }
`;

const Feature = styled.div<{ active: boolean }>`
  padding: 10px 0;
  border-bottom: 1.5px solid ${themeColor("read")};
  cursor: pointer;

  .title {
    display: block;
    font-weight: bold;
    font-size: 1.4rem;
    margin: 20px 0;
    color: ${themeColor("text-primary")};

    ${mobileMediaQuery} {
      margin-left: 20px;
    }
  }

  .description {
    display: block;
    margin: 10px 0;
    color: ${themeColor("text-primary")};

    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin: 0;

    ${mobileMediaQuery} {
      padding-left: 20px;
      font-size: 1.125rem;
      font-style: italic;
    }

    ${(props) =>
      props.active ? "opacity:1; height: auto; margin: 15px 0;" : ""}
  }

  ul {
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin: 0;

    ${(props) =>
      props.active ? "opacity:1; height: auto; margin: 10px 0;" : ""}
  }

  .item {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
`;

const data = [
  {
    title: "Import your dataset",
    descrption: "We support imports as csv, json and Excel files",
    points: [
      "Anotate your dataset metadata",
      "Automatically configure column data types",
      "Future dataset types including Airtable, Google Sheets, Office 365 Excel (coming soon)",
    ],
  },
  {
    title: "Customise your endpoints",
    descrption: "Save a ton of time creating API endpoints without any code.",
    points: [
      "Create any REST endpoints. Support for GET, POST, PUT and DELETE methods",
      "Add filtering to your data",
      "Add custom query and path parameters",
      "Configure searchable fields and have full or partial text search across your dataset",
      "Build autocomplete APIs",
    ],
  },
  {
    title: "Securing your API",
    descrption: "All your endpoints are secured via an API key.",
    points: [
      "Your app has a default API key that is used for testing purposes",
      "You can create API keys for your different use cases",
      "Set your access policy for your API Keys (coming soon)",
      "Add metadata to your API keys that you can use for filtering out your dataset (coming soon)",
    ],
  },
  {
    title: "API documentation",
    descrption:
      "Everyone is familiar with Swagger UI; we generate this for you!",
    points: [
      "Your API will have its own page detailing its existence to the world",
      "Swagger UI with ability to try out your API directly from the browser",
    ],
  },
  {
    title: "Monitoring",
    descrption: "Monitor your API use and find a fully history log of its use.",
    points: [
      "Easy charts to monitor your APIs",
      "Break down of useage of your APIs",
      "Track failing requests",
      "60 minute and 24 hour charts",
      "Average response time",
    ],
  },
  {
    title: "Sell your API (coming soon)",
    descrption: "Want to sell your API as a service? We've got you covered.",
    points: [
      "Easy dashboard where clients can sign up and get access to an API key",
      "Billing handled by us, payments made to you",
      "Out of the box metering solution",
    ],
  },
];

const OurFeatures: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Container id="features">
      <span className="note">NoCode API - The Easy Way</span>
      <h4>Explore our features</h4>
      <h6>The easiest way to turn your data, into a useful API product</h6>
      <FeaturesContainer>
        <div>
          {data.map((d, i) => (
            <Feature
              key={i}
              active={i === activeIndex}
              onClick={() => setActiveIndex(i)}
            >
              <span className="title">{d.title}</span>
              <span className="description">{d.descrption}</span>
              <ul>
                {d.points.map((p, pi) => (
                  <li key={pi} className="item">
                    {p}
                  </li>
                ))}
              </ul>
            </Feature>
          ))}
        </div>
        <div></div>
      </FeaturesContainer>
    </Container>
  );
};

export default OurFeatures;
