import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { themeColor } from "../../../common";
import { Badge, Card } from "../../../components";

const Container = styled(Card)`
  padding: 0 20px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  padding: 5px 10px;
  border: 1px solid ${themeColor("border-primary")};
  border-radius: 5px;
  &:hover {
    background-color: ${themeColor("read")};
    color: ${themeColor("white")};
  }
`;

interface Props {
  title: string;
  count: number;
  to: string;
}

const HomeCard: React.FC<PropsWithChildren<Props>> = ({
  title,
  count,
  to,
  children,
}) => {
  return (
    <Container>
      <Header>
        <h4>
          {title} <Badge variant="read">{count}</Badge>
        </h4>
        <StyledLink to={to}>Create new</StyledLink>
      </Header>
      <hr />
      <div>{children}</div>
    </Container>
  );
};

export default HomeCard;
