import React from "react";
import styled from "styled-components";
import { Button, Card, TextInput } from "../../components";
import * as yup from "yup";
import { useTeam } from "../../api";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";

const Content = styled(Card)`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`;

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
});

const CreateApp: React.FC = () => {
  const { createApp } = useTeam();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async ({ name }, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        await createApp(name);
        navigate(paths.dashboard);
      } catch (e: any) {
        console.error(e);
        // TODO handle error propertly
        setFieldError(
          "name",
          e?.message ?? "An error occured. Please try again."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Content>
      <h3>Create a new app</h3>
      <hr />
      <small>
        Each project is organised into "apps" within <b>apiGO</b>.{" "}
        <p>Within apps, you can setup your datasets, endpoints and API Keys.</p>
      </small>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          name="name"
          label="App name"
          placeholder=""
          value={formik.values.name}
          onChange={formik.handleChange}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <hr />
        <Button variant="success">Create app</Button>
      </form>
    </Content>
  );
};

export default CreateApp;
