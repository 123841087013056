import React, { createContext, PropsWithChildren, useContext } from "react";
import { toast, ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IToastState {
  notify(message: string, type: TypeOptions): void;
}

const toastState: IToastState = {
  notify: () => null,
};

const ToastContext = createContext(toastState);

export const useToast = () => useContext(ToastContext);

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const notify = (message: string, type: TypeOptions) => {
    toast(message, { type });
  };

  return (
    <ToastContext.Provider value={{ notify }}>
      {children}
      <ToastContainer position="bottom-right" theme="dark" />
    </ToastContext.Provider>
  );
};
