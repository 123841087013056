import { useCallback, useRef, useState } from "react";

const MIN_LOADER_SPEED_MILLISECONDS = 250;

export const useLoading = (initialState: boolean = false) => {
  const [loading, setLoading] = useState(initialState);
  let timer = useRef<any | null>(null);

  const onSetLoaded = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(
      () => setLoading(false),
      MIN_LOADER_SPEED_MILLISECONDS
    );
  }, []);

  const onSetLoading = () => {
    setLoading(true);
  };

  return { loading, setLoading: onSetLoading, setLoaded: onSetLoaded };
};
